import React, { useState, useEffect } from "react";
import GoDaddyUI from "./DomainManagement/GoDaddy";
import ExistingDomain from "./DomainManagement/ExistingDomain";
import WebsiteBuildeSteps from "./WebsiteBuilderSteps";
import "./style.css";
import ThemeSelector from "./ThemeManagement/ThemeManagement";
import WebsiteCustomization from "./WebsiteCustomization/WebsiteCustomization";
import HomePageCustomization from "./HomePageCustomization/HomePageCustomization";
import PagesCustomization from "./PagesCustomization/PagesCustomization";
import axios from "axios";
import useTokenFromURL from "../Context/useTokenFromURL";
import useAxios from "../Axios/useAxios";
import { useToken } from "../Context/TokenContext";
import Swal from "sweetalert2";
// Import BASE_URL from config.js
import { BASE_URL } from "../config";
import WebsiteLive from "./websiteLive/websiteLive";
import BasicModal from "./ForSupport";
import Gallery from "./Gallery";
import { useNavigate } from "react-router-dom";
import { FaArrowDownShortWide } from "react-icons/fa6";
import { MdCreate, MdOutlineAddHomeWork, MdOutlineContactPage, MdOutlineCreate, MdOutlineDomainVerification, MdOutlineImage, MdOutlinePlayLesson, MdOutlinePreview, MdOutlineResetTv } from "react-icons/md";
import { IoGridOutline } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import Tooltip from "@mui/material/Tooltip";

/**
 * MultiStepForm Component:
 * This component manages a multi-step form which includes sections for
 * website building, domain management, customization and more.
 */
const MultiStepForm = () => {
  // Hooks to manage the authentication token and setup Axios configurations.
  useTokenFromURL();
  useAxios();
  // State declarations.
  const [step, setStep] = useState(1); // Current step in the multi-step form.
  const [themeSelected, setThemeSelected] = useState(false); // Whether a theme has been selected or not.
  const [clientName, setClientName] = useState("");
  const [liveUrl, setLiveUrl] = useState("");
  const [themeloading, setThemeLoading] = useState(true); // Loading state for async operations.
  const [tokenError, setTokenError] = useState(""); // Error related to token validation.
  const { token } = useToken();
  const [domainStatus, setDomainStatus] = useState(null);
  const { domainStatusTracker } = useToken();
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // New state for domain selection
  const [domainSelection, setDomainSelection] = useState("existing"); // 'existing' or 'new'
  // const {token} = useToken();
  const { setJboId } = useToken();
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Flag to check if there are any unsaved changes.
  const [hasInteracted, setHasInteracted] = useState(false); // Flag to check if the user has interacted with the form.
  const [themeDetails, setThemeDetails] = useState(""); // Flag to check if
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const [bounce, setBounce] = useState(false);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const referrer = document.referrer;
        // j3sitebuilder.ta4.in, sitebuilder.jpencil.in, websitebuilder.jpencil.com
    var hostname = window.location.hostname;
    const hostnameReferrerMap = {
      // "j3sitebuilder.ta4.in": "http://jpjbo.ta4.in/",
      "sitebuilder.jpencil.in": "https://jbo.tanjorepainting.info/",
      "websitebuilder.jpencil.com": "https://dashboard2.jpencil.com/",
    };

    const isLocalhost = hostname.startsWith("localhost");
    const isDev = hostname.startsWith("j3sitebuilder");
    // const isStage = hostname.startsWith("sitebuilder");

    const expectedReferrer = hostnameReferrerMap[hostname];
    if (
      !isLocalhost &&
      !isDev &&
      // !isStage &&
      (!expectedReferrer || !referrer.includes(expectedReferrer))
    ) {
      window.location.href = "/errorPage";
      return;
    }
  }, [history]);

  useEffect(() => {
    if (isResetEnabled) {
      setBounce(true);
      const timer = setTimeout(() => {
        setBounce(false);
        setIsResetEnabled(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isResetEnabled]);

  useEffect(() => {
    const _step = localStorage.getItem("newStep");
    if (_step !== null) {
      setStep(parseInt(_step, 10));
    }
  }, []);

  const fetchThemeManagerData = async () => {
    try {
      await axios
        .get(`${BASE_URL}/theme-manager`)
        .then((res) => {
          setThemeDetails(res.data[0]);
          setJboId(res.data[0].jbo_id);
          setThemeLoading(false);
          setTokenError("");
          res.data[0]?.selected_theme !== 4
            ? setThemeSelected(true)
            : setThemeSelected(false);
        })
        .catch((error) => {
          const errorData =
            error?.response?.data?.message === "jwt malformed"
              ? "Token is Invalid"
              : "";
          console.log("error message", error.response.data.message);
          setTokenError(errorData);
          setThemeLoading(false);
          console.log("errorData", errorData);
          setThemeSelected(false);
          setThemeDetails("");
        });
    } catch (error) {
      setThemeDetails("");
      setTokenError(error);
      console.error(error);
      setThemeSelected(false);
    }
  };

  const fetchDomainData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/domain/domain_status`);

      setDomainStatus(response?.data.domain_status);
    } catch (error) {
      console.error("Error fetching domain data:", error);
    }
  };

  const fetchClientData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/client`);
      setClientName(response.data[0].client_name);
    } catch (error) {
      console.error(error);
    }
  };
  // const fetchDomainData = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/domain/domain_status`);
  //     // setDomainData(response.data);
  //     setDomainStatus(response.data.domain_status);
  //   } catch (error) {
  //     console.error('Error fetching domain data:', error);
  //   }
  // };
  useEffect(() => {
    fetchThemeManagerData();
    fetchClientData();
    fetchDomainData();
  }, [token]);

  const handleMakeWebSiteLive = (newStep) => {
    Swal.fire({
      title:
        '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Are you sure?</span>',
      html: '<p style="font-family: Georgia, serif; color: #555;">Do you want to make your website live?</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Second confirmation with inline styles
        Swal.fire({
          title:
            '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Final Confirmation</span>',
          html: '<p style="font-family: Georgia, serif; color: #555;">By Proceeding, the customizations in your preview will be set as the live content of your website. Do you want to go live?</p>',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, make it live!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            try {
              const response = await axios.post(`${BASE_URL}/go_live`);
              setLoading(false); // Turn off loading indicator after getting response
              // Success message with inline styles
              Swal.fire({
                title:
                  '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Congratulations!</span>',
                html: '<p style="font-family: Georgia, serif; color: #555;">Your website is live now.</p>',
                icon: "success",
                confirmButtonText: "Go Live",
              }).then(() => {
                setLiveUrl(response.data.live_url);
                setStep(newStep);
                // window.open(response.data.live_url, '_blank');
              });
            } catch (error) {
              console.error("Error going live:", error);
              setLoading(false); // Ensure loading is turned off even if there's an error
              // Error message with inline styles
              Swal.fire({
                title:
                  '<span style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif; color: #333;">Error!</span>',
                html: '<p style="font-family: Georgia, serif; color: #555;">There was a problem making your website live. Please try again.</p>',
                icon: "error",
                confirmButtonText: "OK",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "custom-confirm-button",
                  cancelButton: "custom-cancel-button",
                },
              });
              // setStep(newStep);
            }
          }
        });
      }
    });
  };

  /**
   * Handler for tab change. It considers unsaved changes and user interactions
   * before deciding to change the tab.
   * @param {number} newStep - The step to navigate to.
   */
  const handleTabChange = async (newStep, tab) => {
    // If the "Website Live" tab is clicked (assuming step number 8 is for "Website Live")
    if (tab === "Website Live") {
      // Your existing logic for handling the "Website Live" tab
      handleMakeWebSiteLive(newStep, tab);
      // If the "Reset Theme" tab is clicked (assuming step number 6 is for "Reset Theme")
    } else if (tab === "Reset theme") {
      //newStep 7 is reset theme
      // Call the Swal function for resetting the theme
      Swal.fire({
        title: "Are you sure?",
        text: "Resetting the theme will erase all your current settings and data. Do you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("widgetType");
          localStorage.removeItem("widget_name");
          localStorage.removeItem("widgetId");
          resetTheme(); // Call your resetTheme function here
        }
      });
    } else if (!unsavedChanges && hasInteracted && tab !== "Preview") {
      //8 is preview
      // Your existing logic for handling unsaved changes and user interaction for other tabs
      Swal.fire({
        title: "Unsaved changes",
        text: "You have unsaved changes. Are you sure you want to leave?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, leave",
        cancelButtonText: "No, stay",
      }).then((result) => {
        if (result.isConfirmed) {
          setUnsavedChanges(false); // Reset the unsaved changes flag
          setHasInteracted(false); // Reset the user interaction flag
          proceedTabChange(newStep); // Proceed to change the tab
        }
      });
    } else if (tab === "Preview") {
      // Assuming step number 8 is for "Preview"
      try {
        const response = await axios.get(`${BASE_URL}/preview`);
        // Open the preview URL in a new tab
        // window.open(response.data.preview_url, '_blank');
        // Open the preview URL in a new tab
        const previewWindow = window.open(response.data.preview_url, "_blank");
        // Optionally attempt to force reload in the new tab
        if (previewWindow) {
          previewWindow.location.reload(true); // This may or may not force reload from server depending on browser
        }
      } catch (error) {
        console.error("Error fetching preview:", error);
        // Handle the error appropriately (e.g., show an error message)
        Swal.fire({
          title: "Error!",
          text: "There was a problem fetching the preview. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      // No unsaved changes, user interaction, reset, or preview, proceed to change the tab
      proceedTabChange(newStep);
    }
  };

  const resetTheme = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/theme-manager/reset_theme`);
      if (response.status === 200) {
        setThemeSelected(false); // Reset the theme selection state
        setStep(3); // Switch to the ThemeSelector component
        fetchThemeManagerData();
        // setThemeDetails("");
        Swal.fire("Reset!", "Theme has been reset successfully.", "success");
      }
    } catch (error) {
      console.error("Error resetting theme:", error);
      Swal.fire("Error!", "There was a problem resetting the theme.", "error");
    }
  };

  const proceedTabChange = (newStep) => {
    if (!themeSelected && newStep > 2 && newStep !== 9) {
      // Ensure the Preview step can still be accessed to trigger the API call
      setStep(3);
    } else {
      localStorage.setItem("newStep", newStep);
      setStep(newStep);
      localStorage.setItem("widgetType", "");
      localStorage.setItem("widget_name", "");
      localStorage.setItem("widgetId", "");
    }
  };

  /**
   * Method to update the state when a theme is selected.
   */
  const onThemeSelected = () => {
    fetchThemeManagerData();
    setThemeSelected(true);
  };
  // Tab labels for the multi-step form.
  // Define the tabs, always including the "Preview" tab after a theme is selected
  // Initialize the base tabs
  const tabs = [
    "Website Builder Steps",
    "Domain Management",
    "Theme Customization",
    "HomePage Customization",
    "Pages Customization",
    "Website Customization",
    "Gallery",
    "Reset theme",
    ...(themeSelected ? ["Preview"] : []), // Keep "Preview" logic as is
  ];
  const tabIcons = [
    <FaArrowDownShortWide size={16}/>,
    <MdOutlineDomainVerification size={16}/>,
    <IoGridOutline size={16}/>,
    <MdOutlineAddHomeWork size={16}/>,
    <MdOutlineContactPage size={16}/>,
    <CgWebsite size={16}/>,
    <MdOutlineImage size={16}/>,
    <MdOutlineResetTv size={16}/>,
    <MdOutlinePreview size={16}/>,
    <MdOutlinePlayLesson size={16}/>,
    // ...(themeSelected ? ["Preview"] : []), // Keep "Preview" logic as is
  ];

  // Conditionally include "Website Live" tab
  if (domainStatus === "active" && themeSelected) {
    tabs.push("Website Live");
  }
  if (themeloading) {
    return (
      <>
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      </>
    );
  }

  const changeTab = (val) => {
    switch (val) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        setStep(4);
        break;
      case 4:
        if (!themeDetails) {
          return setStep(3);
        }
        setStep(6);
        break;
      case 5:
        handleMakeWebSiteLive(10);
        break;
    }
  };

  return (
    <div className="bg-gray-100 h-[100vh] font-poppins overflow-hidden">
      {tokenError === "Token is Invalid" ? (
        <div className="flex items-center justify-center h-screen">
          <div className="w-[50rem] bg-white shadow-lg rounded-lg p-5">
            <div className="flex justify-center items-center r">
              <img
                className="rounded-lg"
                src="https://blog.scorechain.com/wp-content/uploads/2022/10/Untitled-design-14.jpg"
                alt=""
              />
            </div>
            <h2 className="text-2xl  font-poppins mt-4 text-center">
              Invalid Token
            </h2>
            <p className=" text-xl font-poppins mt-2 text-center">
              Your Token is Invalid/Not Added . Please,try again.
            </p>
          </div>
        </div>
      ) : (
        <div className=" bg-gray-100 w-full">
          <div className="text-center h-[10vh] w-full bg-white border flex  items-center px-2 xl:px-3">
            <div className="w-full flex">
            <div className=" flex flex-row items-center">
               <img src="/jpencil.png" alt="im" height="35" width="35"/>
               {isOpen?<h1 className="text-xl  text-gray-800 font-bold ">JPencil</h1>:null}
            </div>
            <div className="w-full flex flex-row items-center justify-center gap-2 ">
               <h1 className="text-xl  text-gray-800  font-bold ">{clientName}</h1>
            </div>
            </div>
            {/* <h1 className="text-2xl md:text-3xl lg:text-2xl text-gray-700 font-bold mb-2 ">
              Delighted to Welcome
              <span className="uppercase text-blue-700"> {clientName} </span>
              on board! Together, Let's Craft Something Remarkable.
            </h1> */}
          </div>
          <div className="flex  h-[90vh] overflow-hidden">
            <div className=" h-[90vh] content-start ">
              {/* <div className="space-y-4 font-poppins sticky top-8 h-full"> */}
              <div className={`whitespace-nowrap space-y-1 transition-all duration-300 ease-in-out  bg-white pt-4 border shadow-lg sticky top-8  h-full overflow-y-auto flex flex-col items-center px-2 xl:px-3 `}>
                {tabs.map((tab, index) => (
                <Tooltip title={!isOpen?tab:null} key={tab} placement="right">
                  <button
                    key={tab}
                    onClick={() => handleTabChange(index + 1, tab)}
                    className={`${
                      step === index + 1
                        ? "bg-blue-600 text-white border-blue-600"
                        : "bg-white text-gray-600 hover:bg-gray-200"
                    } w-full flex items-center gap-2 rounded-lg text-start px-3 text-sm py-3 focus:outline-none transition-all duration-300 ease-in-out transform  " ${
                      step === index + 1 ? "" : ""
                    } ${
                      index === 7 && !themeSelected
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    } ${
                      bounce && index === 7
                        ? "animate-bounce border-x-4 border-blue-600"
                        : ""
                    }`}
                    disabled={index === 7 && !themeSelected}
                  >
                    {tabIcons[index]}
                    {isOpen?tab:null}
                  </button>
                </Tooltip>
                ))}
                {/* </div> */}
                <div className="absolute right-0 bottom-2 w-full  flex item-center justify-center">      
                <button className=" text-blue-600" onClick={() => setIsOpen(prev => !prev)}>{!isOpen?<IoIosArrowDroprightCircle size={22}/>:<IoIosArrowDropleftCircle size={22}/>}</button>
                </div>

                </div>
            </div>
            <div className="w-full transition-all duration-300 ease-in-out h-[90vh] p-3">
              <div className="h-[93%] ">
                {step === 1 && (
                  <div className="w-full h-full">
                    <WebsiteBuildeSteps
                      callback={changeTab}
                      themeSelected={themeSelected}
                      themeDetails={themeDetails}
                    />
                  </div>
                )}

                {step === 2 && (
                  <div className="flex flex-col items-center justify-center h-full">
                     <div className="h-[5%]">
                      {domainStatusTracker !== "active" &&
                        domainStatusTracker !== "pending" && (
                          <>
                            <label className="inline-flex items-center ml-6">
                              <input
                                type="radio"
                                className="w-5 h-5 text-blue-600 form-radio focus:ring-blue-500 border-gray-300"
                                name="domainType"
                                value="existing"
                                checked={domainSelection === "existing"}
                                onChange={() => setDomainSelection("existing")}
                              />
                              <span className="ml-2 text-gray-700">
                                Existing Domain
                              </span>
                            </label>
                            <label className="inline-flex items-center ml-6">
                              <input
                                type="radio"
                                className="w-5 h-5 text-pink-600 form-radio focus:ring-pink-500 border-gray-300"
                                name="domainType"
                                value="new"
                                checked={domainSelection === "new"}
                                onChange={() => setDomainSelection("new")}
                              />
                              <span className="ml-2 text-gray-700">
                                New Domain
                              </span>
                            </label>
                          </>
                        )}
                    </div>
                    <div className="w-full h-full">
                    {domainSelection === "existing" ? (
                        <ExistingDomain />
                      ) : (
                        <GoDaddyUI />
                      )}
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="w-full h-full">
                    <ThemeSelector
                      onThemeSelected={onThemeSelected}
                      themeDetails={themeDetails}
                      setIsResetEnabled={setIsResetEnabled}
                      themeSelected={themeSelected}
                      setThemeDetails={setThemeDetails}
                    />
                  </div>
                )}

                {!themeSelected && (step === 4 || step === 5) && (
                  <div className="w-full h-full">
                    <ThemeSelector
                      onThemeSelected={onThemeSelected}
                      themeDetails={themeDetails}
                      setIsResetEnabled={setIsResetEnabled}
                      themeSelected={themeSelected}
                      setThemeDetails={setThemeDetails}
                    />
                  </div>
                )}
                {themeSelected && step === 4 && (
                  <div className="w-full h-full">
                    <HomePageCustomization />
                  </div>
                )}
                {themeSelected && step === 5 && (
                  <div className="w-full h-full">
                    <PagesCustomization
                      unsavedChanges={unsavedChanges}
                      setUnsavedChanges={setUnsavedChanges}
                      hasInteracted={hasInteracted}
                      setHasInteracted={setHasInteracted}
                    />
                  </div>
                )}
                {themeSelected && step === 6 && (
                  <div className="w-full h-full">
                    <WebsiteCustomization
                      unsavedChanges={unsavedChanges}
                      setUnsavedChanges={setUnsavedChanges}
                      hasInteracted={hasInteracted}
                      setHasInteracted={setHasInteracted}
                    />
                  </div>
                )}
                {/* here comes the gallery component */}
                {step === 7 && (
                  <div className="w-full h-full">
                    <Gallery dimension="1:1" fromTabs="true" />
                  </div>
                )}
                {step === 9 && <div></div>}
                {step === 10 && (
                  <div>
                    <WebsiteLive liveUrl={liveUrl} />
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-2 h-[6%]">
                <BasicModal />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
