import React from 'react'

export const TemplateBlockFour = ({ templateBlockClick, activeBlock }) => {
  return (
    <div className='flex flex-col w-full text-center'>

      <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4 ${activeBlock === 4 ? "border-red-500 border-2" : ""}`}
        onClick={() => templateBlockClick(4,7)}
      >
        <div className="w-full flex justify-evenly gap-4">
          <div className="w-1/3 h-32 border-2 border-blue-500"></div>
          <div className="w-1/3 h-32 border-2 border-blue-500"></div>
          <div className="w-1/3 h-32 border-2 border-blue-500"></div>
        </div>
        <div className="w-full flex justify-evenly gap-4">
          <div className="w-2/3 h-32 border-2 border-blue-500"></div>
          <div className="w-1/3 h-32 border-2 border-blue-500"></div>
        </div>
        <div className="w-full flex justify-evenly gap-4">
          <div className="w-1/3 h-32 border-2 border-blue-500"></div>
          <div className="w-2/3 h-32 border-2 border-blue-500"></div>
        </div>
      </div>
      <p className='font-bold'>Type 5</p>
    </div>
  )
}
