import React from 'react'
import { RenderCommonUpload } from '../renderCommonUpload'

export const CollageBlockFive = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
  return (
    <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4 `}>
        <div className="w-full flex justify-evenly gap-4">
          <div className="w-1/3 border-2 border-blue-500 md:h-[105] lg:h-[160px]">
              <RenderCommonUpload buttonIndex={0} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:5.8"}/>
          </div>
          <div className="w-1/3 border-2 border-blue-500 md:h-[105] lg:h-[160px] ">
              <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:5.8"}/>
          </div>
          <div className="w-1/3 border-2 border-blue-500 md:h-[105] lg:h-[160px] ">
              <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:5.8"}/>
          </div>
        </div>
        
    </div>
  )
}
