import React, { useState } from "react";
import ProductCustomization from "./ProductCustomization";
import FooterCustomization from "./FooterCustomization";
import MenuCustomization from "./MenuCustomization";
import HeaderCustomization from "./HeaderCustomization";
import WebsiteStandard from "./WebsiteStandard";

/**
 * WebsiteCustomization Component
 *
 * This component serves as the main hub for customizing various sections
 * of a website. It contains several accordions that help users to customize
 * specific parts of the website, like headers, menus, products, and footers.
 *
 * Props:
 * - setUnsavedChanges: A function callback to set a flag indicating whether there are unsaved changes.
 * - setHasInteracted: A function callback to set a flag indicating whether user interactions have taken place.
 */

const WebsiteCustomization = ({ setUnsavedChanges, setHasInteracted }) => {
  // State to keep track of the currently active accordion.
  const [activeAccordion, setActiveAccordion] = useState(null);
  /**
   * Handle the toggling of accordions.
   *
   * This function checks which accordion is currently active and either
   * opens the clicked accordion or closes it if it's already open.
   *
   * @param {String} accordionName - The name of the accordion being toggled.
   */
  const handleToggleAccordion = (accordionName) => {
    if (activeAccordion === accordionName) {
      setActiveAccordion(null); // close the current accordion
    } else {
      setActiveAccordion(accordionName); // open the clicked accordion
    }
  };
  /**
   * Set flags for user interactions and unsaved changes.
   *
   * This function marks that user has interacted with an input and
   * there are changes which haven't been saved yet.
   */
  const IsSavedInputs = () => {
    setHasInteracted(true);
    setUnsavedChanges(true);
  };

  return (
    <div className="flex flex-col bg-white border shadow-lg rounded-lg items-center space-y-8 py-8 px-6 h-full ">
      <h1 className="text-lg font-bold text-start w-full font-poppins text-gray-700 ">
      Basic Customizations
      </h1>

      <div className="w-full  h-full overflow-auto flex flex-col space-y-4 py-2">
        {/* Website Standard Accordian */}
        <div className="w-full ">
          <WebsiteStandard
            activeAccordion={activeAccordion}
            onToggle={handleToggleAccordion}
            IsSavedInputs={IsSavedInputs}
          />
        </div>
        {/* header accordian */}
        <div className="w-full ">
          <HeaderCustomization
            activeAccordion={activeAccordion}
            onToggle={handleToggleAccordion}
            IsSavedInputs={IsSavedInputs}
          />
        </div>
        {/* Menu Accordian */}
        <div className="w-full ">
          <MenuCustomization
            activeAccordion={activeAccordion}
            onToggle={handleToggleAccordion}
            IsSavedInputs={IsSavedInputs}
          />
        </div>
        {/* Products Customization Accordian */}
        <div className="w-full ">
          <ProductCustomization
            activeAccordion={activeAccordion}
            onToggle={handleToggleAccordion}
            IsSavedInputs={IsSavedInputs}
          />
        </div>
        {/* Footer Customization Accordian */}
        <div className="w-full ">
          <FooterCustomization
            activeAccordion={activeAccordion}
            onToggle={handleToggleAccordion}
            IsSavedInputs={IsSavedInputs}
          />
        </div>
      </div>

    </div>
  );
};
export default WebsiteCustomization;
