import React, { useState, useEffect, useRef, useCallback } from "react";
import { ClipLoader } from "react-spinners";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { useDropzone } from "react-dropzone";
import ErrorUI from "./Validation/errorUI";
import Select from "react-select";

// this url can be used for cropping due to cors error in local env.
// imgURL = "https://api.sportswander.com/images/event/sportswander_event-1709202010420.jpg"

const ImageCropper2 = ({
  imgURL,
  dimension,
  handleCropData,
  onFilterByRatio,
  activeTab,
  refresh,
  fromTabs,
  setLoader,
  loader,
  setSelectedAspectRatioForGallery,
}) => {
  const [selectedAspectRatio, setSelectedAspectRatio] = useState("1:1");
  const [purposeFor, setPurposeFor] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [checkIfuploadedImg, setCheckIfuploadedImg] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  // const [uploadProgress, setUploadProgress] = useState(0);
  const imageElement = useRef(null);
  const [filterByRatio, setFilterByRatio] = useState(dimension);

  const onDrop = useCallback((acceptedFiles) => {
    handleImageUpload({ target: { files: acceptedFiles } });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [show, setShow] = useState(true);

  useEffect(() => {
    setSelectedAspectRatio(dimension);
    setSelectedAspectRatioForGallery(dimension);
  }, [dimension]);

  useEffect(() => {
    // Check if imgURL has changed or if it's the first render
    if (imgURL !== uploadedImage || !uploadedImage) {
      setUploadedImage(imgURL);
      setCheckIfuploadedImg(true);
    }
  }, [imgURL, refresh]);

  useEffect(() => {
    if (activeTab == "public") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [activeTab]);

  useEffect(() => {
    if (cropper) {
      cropper.destroy();
      // setUploadedImage(null)
    }
    if (uploadedImage) {
      initiailizeCropper();
    }
  }, [uploadedImage, selectedAspectRatio]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    setErrorMsg("");

    // Checks if file is larger than 4 MB
    if (file && file.size > 4 * 1024 * 1024) {
      setErrorMsg("File size must not exceed 4 MB.");
      return false;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (file.type == "image/gif") {
          handleCropData(reader.result, 1);
        } else {
          setUploadedImage(reader.result);
          setCheckIfuploadedImg(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const aspectRatios = [
    { value: "1:1", label: "Aspect Ratio (1:1)" },
    { value: "2:3", label: "Aspect Ratio (2:3)" },
    { value: "3.7:3", label: "Aspect Ratio (3.7:3)" },
    { value: "4:3", label: "Aspect Ratio (4:3)" },
    { value: "4.2:3.9", label: "Aspect Ratio (4.2:3.9)" },
    { value: "4.4:3.2", label: "Aspect Ratio (4.4:3.2)" },
    { value: "5.5:5.3", label: "Aspect Ratio (5.5:5.3)" },
    { value: "5.5:5.8", label: "Aspect Ratio (5.5:5.8)" },
    { value: "6.4:2.5", label: "Aspect Ratio (6.4:2.5)" },
    { value: "6.8:5.7", label: "Aspect Ratio (6.8:5.7)" },
    { value: "7.4:4.1", label: "Aspect Ratio (7.4:4.1)" },
    { value: "7.4:4.7", label: "Aspect Ratio (7.4:4.7)" },
    { value: "7.4:5.8", label: "Aspect Ratio (7.4:5.8)" },
    { value: "7.4:6", label: "Aspect Ratio (7.4:6)" },
    { value: "7.4:8.6", label: "Aspect Ratio (7.4:8.6)" },
    { value: "7.4:9.8", label: "Aspect Ratio (7.4:9.8)" },
    { value: "11.2:4.7", label: "Aspect Ratio (11.2:4.7)" },
    { value: "11.2:9.7", label: "Aspect Ratio (11.2:9.7)" },
    { value: "15.1:5.7", label: "Aspect Ratio (15.1:5.7)" },
    { value: "15.1:6", label: "Aspect Ratio (15.1:6)" },
    { value: "32:9", label: "Aspect Ratio (32:9)" },
    { value: "32:12", label: "Aspect Ratio (32:12)" },
    { value: "36:13", label: "Aspect Ratio (36:13)" },
    { value: "36:14", label: "Aspect Ratio (36:14)" },
    { value: "36:18", label: "Aspect Ratio (36:18)" },
    { value: "37:20", label: "Aspect Ratio (37:20)" },
    { value: "38:18", label: "Aspect Ratio (38:18)" },
    { value: "freeform", label: "Aspect Ratio (Custom)" },
    { value: "gif", label: "Aspect Ratio (GIF)" },
  ];

  const handleFilterChange = (data) => {
    const value = data.value;
    setFilterByRatio(data);

    if (activeTab == "public") {
      onFilterByRatio(value, "public");
    } else {
      onFilterByRatio(value, "own");
    }
  };

  const handleCropImage = async (data) => {
    setLoader(true);
    if (cropper) {
      var type = "";
      if (data === "save") {
        type = 3;
      } else {
        type = 1;
      }
      const croppedCanvas = cropper.getCroppedCanvas();

      croppedCanvas.toBlob(
        (blob) => {
          const croppedImageDataURL = URL.createObjectURL(blob);
          handleCropData(croppedImageDataURL, type);
          cropper.destroy();
          setUploadedImage(null);
          setLoader(false);
        },
        "image/png",
        0.3
      );
    }
  };

  function initiailizeCropper() {
    let aspectRatio;

    if (selectedAspectRatio === "freeform") {
      aspectRatio = NaN;
    } else {
      const [width, height] = selectedAspectRatio.split(":").map(Number);
      aspectRatio = width / height;
    }

    const newCropper = new Cropper(imageElement.current, {
      aspectRatio: aspectRatio,
      viewMode: 1,
    });

    setCropper(newCropper);
  }

  // function initiailizeCropper() {
  //   let aspectRatio;
  //   switch (selectedAspectRatio) {
  //     case "1:1":
  //       aspectRatio = 1;
  //       break;
  //     case "16:9":
  //       aspectRatio = 16 / 9;
  //       break;
  //     case "4:3":
  //       aspectRatio = 4 / 3;
  //       break;
  //     case "6.4:2.5":
  //       aspectRatio = 6.4 / 2.5;
  //       break;
  //     case "3.7:3":
  //       aspectRatio = 3.7 / 3;
  //       break;
  //     case "2:3":
  //       aspectRatio = 2 / 3;
  //       break;
  //     case "32:20":
  //       aspectRatio = 32 / 20;
  //       break;
  //     case "32:9":
  //       aspectRatio = 32 / 9;
  //       break;
  //     case "36:13": //for desktops
  //       aspectRatio = 36 / 14;
  //       break;
  //     case "36:18": //for tablets
  //       aspectRatio = 37 / 20;
  //       break;
  //     case "38:18": //for mobile devices
  //       aspectRatio = 40 / 29;
  //       break;
  //     case "freeform":
  //       aspectRatio = NaN;
  //       break;
  //     case "6.8:5.7":
  //       aspectRatio = 6.8 / 5.7;
  //       break;
  //     case "15.1:5.7":
  //       aspectRatio = 15.1 / 5.7;
  //       break;
  //     case "7.4:5.8":
  //       aspectRatio = 7.4 / 5.8;
  //       break;
  //     case "5.5:5.8":
  //       aspectRatio = 5.5 / 5.8;
  //       break;
  //     case "4.2:3.9":
  //       aspectRatio = 4.2 / 3.9;
  //       break;
  //     case "5.5:5.3":
  //       aspectRatio = 5.5 / 5.3;
  //       break;
  //     case "11.2:5.3":
  //       aspectRatio = 11.2 / 5.3;
  //       break;
  //     case "7.4:4.1":
  //       aspectRatio = 7.4 / 4.1;
  //       break;
  //     case "7.4:8.6":
  //       aspectRatio = 7.4 / 8.6;
  //       break;
  //     case "11.2:9.7":
  //       aspectRatio = 11.2 / 9.7;
  //       break;
  //     case "11.2:4.7":
  //       aspectRatio = 11.2 / 4.7;
  //       break;
  //     case "7.4:9.8":
  //       aspectRatio = 7.4 / 9.8;
  //       break;
  //     case "7.4:4.7":
  //       aspectRatio = 7.4 / 4.7;
  //       break;
  //     case "7.4:6":
  //       aspectRatio = 7.4 / 6;
  //       break;
  //     case "15.1:6":
  //       aspectRatio = 15.1 / 6;
  //       break;
  //     default:
  //       aspectRatio = 1;
  //   }

  //   const newCropper = new Cropper(imageElement.current, {
  //     aspectRatio: aspectRatio,
  //     viewMode: 1,
  //   });

  //   setCropper(newCropper);
  // }

  // const ProgressBar = ({ progress }) => (
  //   <div className="w-full h-8 bg-gray-200 rounded-lg relative">
  //     <div
  //       className="h-full bg-gradient-to-r from-blue-500 mt-[16px]  via-purple-500 to-red-500 w-full transition-all duration-300 ease-in-out"
  //       style={{ width: `${progress}%` }}
  //     ></div>
  //     <span className="text-sm absolute top-0 left-0 w-full font-poppins h-full flex items-center justify-center ">
  //       {progress}%
  //     </span>
  //   </div>
  // );

  return (
    <>
      <div>
        <div className="bg-gray-100 p-6 rounded-md">
          {show && (
            <div>
              {uploadedImage && (
                <>
                  {!uploadedImage && (
                    <div className="font-poppins text-xl font-medium pb-6 ">
                      Upload Your Own photos Here
                    </div>
                  )}

                  <div className="mb-4 flex gap-5 flex-col md:flex-row items-center">
                    {!uploadedImage ||
                      (fromTabs && (
                        <div className="w-full md:w-1/3">
                          <label
                            htmlFor="aspectRatio"
                            className="font-medium text-gray-700"
                          >
                            Select Aspect ratio
                          </label>
                          <select
                            id="aspectRatio"
                            name="aspectRatio"
                            value={selectedAspectRatio}
                            onChange={(e) => {
                              setSelectedAspectRatio(e.target.value);
                              setSelectedAspectRatioForGallery(e.target.value);
                            }}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          >
                            <option value="1:1">Square (1:1)</option>
                            <option value="3.7:3">Widescreen (3.7:3)</option>
                            <option value="6.4:2.5">
                              Ultra Widescreen (6.4:2.5)
                            </option>
                            <option value="36:13">
                              Desktop Banner (36:13)
                            </option>
                            <option value="36:18">Tab Banner (36:18)</option>
                            <option value="38:18">Mobile Banner (38:18)</option>
                            <option value="4:3">Standard (4:3)</option>
                            <option value="32:20">
                              Wide Rectangle (32:20)
                            </option>
                            <option value="2:3">Tall Rectangle (2:3)</option>
                            <option value="32:9">Ultra Wide (32:9)</option>
                            <option value="32:12"> Wide (32:12)</option>
                            <option value="freeform">Custom Ratio</option>
                          </select>
                        </div>
                      ))}

                    <div className="w-full md:w-1/3">
                      <label
                        htmlFor="purposeFor"
                        className="font-medium text-gray-700"
                      >
                        Purpose For
                      </label>
                      <select
                        id="purposeFor"
                        name="purposeFor"
                        value={purposeFor}
                        onChange={(e) => setPurposeFor(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      >
                        <option value="banner">Banner Post</option>
                        <option value="blog">Blog Post</option>
                        <option value="widget">Widget Post</option>
                        <option value="product">Product Post</option>
                      </select>
                    </div>
                    <div className="w-full md:w-1/3">
                      {uploadedImage && (
                        <div>
                          {checkIfuploadedImg ? (
                            <button
                              onClick={() => handleCropImage("save")}
                              className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 hover:bg-green-600 transition duration-200 ease-in-out"
                            >
                              {loader ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="mx-1">
                                    <ClipLoader size={20} />
                                  </div>
                                  <span className="ml-2">Loading...</span>
                                </div>
                              ) : (
                                "Crop & Save"
                              )}
                            </button>
                          ) : (
                            <button
                              onClick={() => handleCropImage("upload")}
                              className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 hover:bg-green-600 transition duration-200 ease-in-out"
                            >
                              {loader ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="mx-1">
                                    <ClipLoader size={20} />
                                  </div>
                                  <span className="ml-2">Loading...</span>
                                </div>
                              ) : (
                                "Crop & Upload"
                              )}
                            </button>
                          )}

                          {/* <ProgressBar progress={uploadProgress} /> */}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <>
                {uploadedImage ? (
                  <div className="mb-4">
                    <img
                      ref={imageElement}
                      src={uploadedImage}
                      alt="Uploaded"
                      style={{ maxHeight: "400px", maxWidth: "100%" }}
                    />
                  </div>
                ) : (
                  <div
                    {...getRootProps()}
                    className={`dropzone mb-1 ${errorMsg ? "border-solid border-2 border-red-400" : ""
                      } `}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p className="text-center">Drop the files here...</p>
                    ) : (
                      <>
                        <p className="text-center">
                          {" "}
                          Drag and drop images here, or click to select files
                        </p>
                      </>
                    )}
                  </div>
                )}
                {errorMsg && <ErrorUI message={errorMsg} />}
              </>
            </div>
          )}
          {!show && uploadedImage && (
            <div className="">
              <div className="flex justify-end w-full mb-3">
                <button
                  onClick={() => handleCropImage("save")}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 hover:bg-green-600 transition duration-200 ease-in-out"
                >
                  {loader ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="mx-1">
                        <ClipLoader size={20} />
                      </div>
                      <span className="ml-2">Loading...</span>
                    </div>
                  ) : (
                    "Crop & Savee"
                  )}
                </button>
                {/* <ProgressBar progress={uploadProgress} /> */}
              </div>

              <div className="mb-4">
                <img
                  ref={imageElement}
                  src={uploadedImage}
                  alt="Uploaded"
                  style={{ maxHeight: "400px", maxWidth: "100%" }}
                />
              </div>
            </div>
          )}

          <div>
            {!fromTabs && (
              <div className="flex justify-center">
                <h1 className="text-blue-500 text-lg bold border-2 border-blue-500 rounded-md p-2">
                  Required Dimension -{" "}
                  {dimension === "freeform" ? "Custom Ratio" : dimension}{" "}
                </h1>
              </div>
            )}

            <div className="mb-4 flex gap-5 flex-col md:flex-row justify-end mt-2">
              <div className="w-60">
                <label
                  htmlFor="aspectRatio"
                  className="font-medium text-gray-700"
                >
                  Filter by
                </label>
                <Select
                  options={aspectRatios}
                  value={filterByRatio}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageCropper2;
