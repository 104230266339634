import React from 'react'

export const TemplateBlockOne = ({ templateBlockClick, activeBlock,setImageCountForSelectedTemplate }) => {
  return (
    <div className='flex flex-col w-full text-center'>

      <div
        onClick={() => {templateBlockClick(1);setImageCountForSelectedTemplate(4)}}
        className={`w-full p-4 bg-gray-200 rounded-lg flex ${activeBlock === 1 ? "border-red-500" : ""
          } border-2`}
      >
        <div className="w-2/3 h-40 border-2 rounded-lg border-blue-500"></div>
        <div className="w-1/3 flex flex-col justify-between ml-2">
          <div className="h-12 border-2 rounded-sm border-blue-500 mb-1"></div>
          <div className="h-12 border-2 rounded-sm border-blue-500 mb-1"></div>
          <div className="h-12 border-2 rounded-sm border-blue-500"></div>
        </div>
      </div>
      <p className='font-bold'>Type 2</p>
    </div>
  )
}







