import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FiUpload } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import {MdFileUpload } from "react-icons/md";
import Gallery from "../Gallery";
import Modal from "react-modal";
import ReactSwitch from "react-switch";
import { MdDelete } from "react-icons/md";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import Swal from "sweetalert2";
import { BASE_URL } from '../../config';
import { useToken } from '../../Context/TokenContext';
const REACT_APP_BASE_URL = BASE_URL;

// The AboutUs component represents a section to manage content for the 'About Us' page.
const AboutUs = ({
  onToggle, // Function to handle accordion toggle.
  activeAccordion, // Active accordion string identifier.
  IsSavedInputs, // Callback function to signal if inputs are saved.
}) => {
  useTokenFromURL(); // Extract token from the URL.
  useAxios(); // Set up axios for HTTP requests.

  // States to track various about us data and UI elements.
  const [currentInfoDataIndex, setCurrentInfoDataIndex] = useState(null);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [defaultDescription, setDefaultDescription] = useState("<p><br></p>");
  const [currentProfileDataIndex, setCurrentProfileDataIndex] = useState(null);
  const {jboId} = useToken();

  const [aboutUs, SetAboutUs] = useState({
    about_us_page_id: null,
    MainDescription: "",
    historyToggle: false,
    infoManagementToggle: false,
    profileManagementToggle: false,
    historyDescription: "",
  });
  // References to hold the previous state of certain descriptions for comparison.
  const prevDescriptionRef = useRef(aboutUs.MainDescription);
  const prevDescriptionRef2 = useRef(aboutUs.historyDescription);

  const [aboutUsInfo, setAboutUsInfo] = useState([
    { info_management_id: null, icon: null, title: "", description: "" },
  ]);
  const prevDescriptionRef1 = useRef(aboutUsInfo.description);
  const [aboutUsProfile, setAboutUsProfile] = useState([
    {
      profile_management_id: null,
      profileImage: null,
      name: "",
      designation: "",
    },
  ]);
  const [errors, setErrors] = useState({});

  // Function to handle accordion change (open/close) behavior.
  const handleAccordionChange = () => {
    if (activeAccordion === "AboutUsPageAccordian") {
      onToggle && onToggle(null); // close the current accordion if it's the 'AboutUsPageAccordian'.
    } else {
      onToggle && onToggle("AboutUsPageAccordian"); // open the 'AboutUsPageAccordian' if another accordion is currently active.
    }
  };
  // CRUD operations for 'AboutUs' informational data.

  // Add a new blank info to the aboutUsInfo list.
  const addNewInfoData = () => {
    setAboutUsInfo([
      ...aboutUsInfo,
      { icon: null, title: "", description: "" },
    ]);
    IsSavedInputs();
  };

  const updateInfoData = (index, field, value) => {
    // Update specific field in an info at the given index.
    const updatedInfoDatas = [...aboutUsInfo];
    updatedInfoDatas[index][field] = value;
    setAboutUsInfo(updatedInfoDatas);
  };

  const deleteInfoData = (index) => {
    // Remove an info at the given index.
    const updatedInfoDatas = [...aboutUsInfo];
    updatedInfoDatas.splice(index, 1);
    setAboutUsInfo(updatedInfoDatas);
    IsSavedInputs();
  };

  // CRUD operations for 'AboutUs' profile data.

  // Add a new blank profile to the aboutUsProfile list.
  const addNewProfile = () => {
    setAboutUsProfile([
      ...aboutUsProfile,
      { profileImage: null, name: "", designation: "" },
    ]);
    IsSavedInputs();
  };

  // Update specific field in a profile at the given index.
  const updateProfile = (index, field, value) => {
    const updatedProfiles = [...aboutUsProfile];
    updatedProfiles[index][field] = value;
    setAboutUsProfile(updatedProfiles);
  };

  // Remove a profile at the given index.
  const deleteProfile = (index) => {
    const updatedProfiles = [...aboutUsProfile];
    updatedProfiles.splice(index, 1);
    setAboutUsProfile(updatedProfiles);
    IsSavedInputs();
  };

  // Handle selection of images for information and profiles.

  // Update the image icon for a specific info
  const handleImageSelect1 = (image, index) => {
    const updatedInfoDatas = [...aboutUsInfo];
    updatedInfoDatas[index].icon = image;
    setAboutUsInfo(updatedInfoDatas);
    setModalIsOpen2(false);
  };

  // Update the profile image for a specific profile.
  const handleProfileImageSelect = (image, index) => {
    const updatedProfiles = [...aboutUsProfile];
    updatedProfiles[index].profileImage = image;
    setAboutUsProfile(updatedProfiles);
    setProfileModalIsOpen(false);
  };

  // Fetch data about the 'About Us' page from the backend.
  const fetchData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/about-us-page`);
      if (response.data) {
        setDataSubmitted(true);
      }
      const data = response.data[0]; // Since the API seems to return an array, we'll consider only the first item.

      // Update your states based on the received data
      SetAboutUs((prevState) => ({
        ...prevState,
        about_us_page_id: data.about_us_page_id,
        MainDescription: data.about_us_description,
        historyToggle: !!data.is_history,
        infoManagementToggle: !!data.is_info_management,
        profileManagementToggle: !!data.is_profile_management,
        historyDescription: data.history_description,
      }));
      prevDescriptionRef.current = data.about_us_description;
      prevDescriptionRef2.current = data.history_description;
      const infoManagementData = data.info_management.map((info) => ({
        description: info.info_description,
      }));

      // Capture all the 'description' data from infoManagementData
      const allDescriptions = infoManagementData
        .map((info) => info.description)
        .join(" ");
      prevDescriptionRef1.current = allDescriptions;
      setAboutUsInfo(
        data.info_management.map((info) => ({
          info_management_id: info.info_management_id,
          icon: info.icon_image,
          title: info.info_title,
          description: info.info_description,
        }))
      );

      setAboutUsProfile(
        data.profile_management.map((profile) => ({
          profile_management_id: profile.profile_management_id,
          profileImage: profile.profile_image,
          name: profile.profile_name,
          designation: profile.designation,
        }))
      );
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "About Us Page Not found"
      ) {
        setDataSubmitted(false);
      }
      console.error("Error fetching data:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  // Invoke fetchData when the component mounts and whenever dataSubmitted changes.
  useEffect(() => {
    fetchData();
  }, [dataSubmitted]); // Empty dependency array ensures the effect runs only once when the component mounts.

  // Validate the form inputs for completeness and correctness.
  const validateForm = () => {
    let formErrors = {};

    if (
      !aboutUs.MainDescription?.trim() ||
      aboutUs.MainDescription === defaultDescription
    ) {
      formErrors.MainDescription = "AboutUs Description is required!";
    }
    // Check if historyDescription is provided only if historyToggle is turned on
    if (
      aboutUs.historyToggle &&
      (!aboutUs.historyDescription?.trim() ||
        aboutUs.historyDescription === defaultDescription)
    ) {
      formErrors.historyDescription = "History Description is required!";
    }

    // Validate aboutUsInfo only if infoManagementToggle is on
    if (aboutUs.infoManagementToggle) {
      aboutUsInfo.forEach((info, index) => {
        if (!info.icon) {
          formErrors[`infoIcon${index}`] = "Icon is required!";
        }
        if (!info.title?.trim()) {
          formErrors[`infoTitle${index}`] = "Title is required!";
        }
        if (
          !info.description?.trim() ||
          info.description === defaultDescription
        ) {
          formErrors[`infoDescription${index}`] = "Description is required!";
        }
      });
    }

    // Validate aboutUsProfile only if profileManagementToggle is on
    if (aboutUs.profileManagementToggle) {
      aboutUsProfile.forEach((profile, index) => {
        if (!profile.profileImage) {
          formErrors[`profileImage${index}`] = "Profile image is required!";
        }
        if (!profile.name?.trim()) {
          formErrors[`profileName${index}`] = "Name is required!";
        }
        if (!profile.designation?.trim()) {
          formErrors[`profileDesignation${index}`] = "Designation is required!";
        }
      });
    }
    setErrors(formErrors);
  };

  // Submit the form: Prepare data and send updates to the backend.
  const handleSubmit = async () => {
    try {
      // Prepare the payload
      const payload = {
        about_us_description: aboutUs.MainDescription,
        is_info_management: aboutUs.infoManagementToggle ? 1 : 0,
        is_profile_management: aboutUs.profileManagementToggle ? 1 : 0,
        is_history: aboutUs.historyToggle ? 1 : 0,
        history_description: aboutUs.historyDescription,

        info_management: aboutUsInfo.map((info) => ({
          info_management_id: info.info_management_id,
          info_title: info.title,
          info_description: info.description,
          icon_image: info.icon,
        })),
        profile_management: aboutUsProfile.map((profile) => ({
          profile_name: profile.name,
          designation: profile.designation,
          profile_image: profile.profileImage,
        })),
      };

      // Send the payload to the backend using axios
       await axios.patch(
        `${REACT_APP_BASE_URL}/about-us-page`,
        payload
      );
      if (dataSubmitted) {
        Swal.fire({
          title: "Success!",
          text: "Datas Updated successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Success!",
          text: "Datas saved successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
      fetchData();
      // Handle the response as necessary, e.g., show a success message or update the UI
      // If there's any additional logic for successful API call, place it here.
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  // Validate the form whenever main data states change.

  useEffect(() => {
    validateForm();
  }, [aboutUs, aboutUsInfo, aboutUsProfile]);

  return (
    <div className="px-2">
      <Accordion
        expanded={activeAccordion === "AboutUsPageAccordian"}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{ fontFamily: "poppins" }}
            className="font-medium text-xl font-poppins"
          >
            About Us Page
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="flex gap-3 flex-col">
          <div className="w-full space-y-6">
            <label className="font-medium font-poppins text-gray-600 block">
              Description
            </label>
            <SunEditor
              setDefaultStyle="font-family: poppins; font-size: 15px;"
              setOptions={{
                height: 400,
                buttonList: [
                  [
                    "font",
                    "fontSize",
                    "subscript",
                    "superscript",
                    "bold",
                    "italic",
                    "underline",
                  ], // Added 'font'
                  ["fontColor", "hiliteColor", "outdent", "indent"],
                  ["codeView", "undo", "redo", "align", "list", "table"], // Added 'codeView'
                  ["fullScreen", "preview"],
                ],
                // imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                // To define custom fonts:
                font: [
                  "Arial",
                  "Calibri",
                  "Comic Sans",
                  "Courier",
                  "Poppins",
                  "Times New Roman",
                ],
                // You can add other options according to your needs
              }}
              setContents={aboutUs.MainDescription}
              // onChange={(content) =>
              //   SetAboutUs({ ...aboutUs, MainDescription: content })
              // }

              onChange={(content) => {
                if (prevDescriptionRef.current !== content) {
                  IsSavedInputs();
                }
                prevDescriptionRef.current = content; // Update the ref with the new content
                SetAboutUs((prevState) => ({
                  ...prevState,
                  MainDescription: content,
                }));
              }}
            />
            <div className="mt-3">
              {errors.MainDescription && (
                <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                  {errors.MainDescription}
                </span>
              )}
            </div>
            {/* <textarea
            value={aboutUs.MainDescription}
            onChange={(e) =>
              SetAboutUs((prevState) => ({
                ...prevState,
                MainDescription: e.target.value,
              }))
            }
            placeholder="About Us Description"
            rows="3"
            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          /> */}
          </div>

          <div className="flex w-full items-center gap-4 mt-3">
            <label className="font-medium font-poppins text-gray-600 block">
              Info Management :
            </label>
            <ReactSwitch
              checked={aboutUs.infoManagementToggle}
              onChange={(isChecked) => {
                SetAboutUs((prevState) => ({
                  ...prevState,
                  infoManagementToggle: isChecked,
                }));
              }}
            />
          </div>

          {aboutUs.infoManagementToggle && (
            <>
              {aboutUsInfo.map((infoData, idx) => (
                <Accordion key={idx}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="font-medium">
                      Information Box {idx + 1}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="flex justify-between items-start">
                    <div className="w-full space-y-6">
                      <label className="font-medium font-poppins text-gray-600 block">
                        Info Title
                      </label>
                      <input
                        value={infoData.title}
                        onChange={(e) => {
                          IsSavedInputs();
                          updateInfoData(idx, "title", e.target.value);
                        }}
                        type="text"
                        placeholder="Info Title"
                        className="mt-1 w-2/3 focus:ring-blue-500 focus:border-blue-500 block  sm:text-sm border-gray-300 rounded-md"
                      />
                      <div className="mt-1">
                        {errors[`infoTitle${idx}`] && (
                          <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                            {errors[`infoTitle${idx}`]}
                          </span>
                        )}
                      </div>
                      <label className="font-medium font-poppins text-gray-600 block">
                        Info Description
                      </label>
                      <SunEditor
                        setDefaultStyle="font-family: poppins; font-size: 15px;"
                        setOptions={{
                          height: 400,
                          buttonList: [
                            [
                              "font",
                              "fontSize",
                              "bold",
                              "subscript",
                              "superscript",
                              "underline",
                            ], // Added 'font'
                            [
                              "fontColor",
                              "hiliteColor",
                              "outdent",
                              "indent",
                              "link",
                            ],
                            [
                              "codeView",
                              "undo",
                              "redo",
                              "align",
                              "list",
                              "table",
                            ], // Added 'codeView'
                          ],
                          // imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                          // To define custom fonts:
                          font: [
                            "Arial",
                            "Calibri",
                            "Comic Sans",
                            "Courier",
                            "Poppins",
                            "Times New Roman",
                          ],
                          // You can add other options according to your needs
                        }}
                        onChange={(content) => {
                          // Compare the current content with the previous content
                          if (prevDescriptionRef1.current !== content) {
                            IsSavedInputs();
                          }
                          prevDescriptionRef1.current = content;
                          updateInfoData(idx, "description", content);
                        }}
                        // value={infoData.description}
                        setContents={infoData.description}
                      />
                      <div className="mt-1">
                        {errors[`infoDescription${idx}`] && (
                          <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                            {errors[`infoDescription${idx}`]}
                          </span>
                        )}
                      </div>

                      <label className="font-medium font-poppins text-gray-600 block">
                        Icon Image
                      </label>
                      {infoData.icon ? (
                        <div className="relative mt-2 inline-block">
                          <img
                            src={infoData.icon}
                            alt="Selected Design"
                            className="w-20 h-20 object-cover rounded shadow-md"
                          />
                          <button
                            onClick={() => {
                              const updatedInfoDatas = [...aboutUsInfo];
                              updatedInfoDatas[idx].icon = null;
                              setAboutUsInfo(updatedInfoDatas);
                              IsSavedInputs();
                            }}
                            className="absolute top-4 right-4 bg-gray-100 text-red-600 font-bold rounded-full hover:bg-red-200 focus:ring focus:ring-red-200 transform translate-x-1/2 -translate-y-1/2"
                          >
                            <AiOutlineClose/>
                          </button>
                          <button onClick={() => {
                            setCurrentInfoDataIndex(idx);
                            setModalIsOpen2(true);
                          }} className="absolute top-8 right-4 bg-gray-100 text-blue-600 font-bold rounded-full hover:bg-red-200 focus:ring focus:ring-red-200 transform translate-x-1/2 -translate-y-1/2">
                          <MdFileUpload/>
                          </button>
                          
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setCurrentInfoDataIndex(idx);
                            setModalIsOpen2(true);
                          }}
                          className="flex   items-center space-x-2 text-blue-600 font-poppins p-3 border-blue-500 border-2  rounded  hover:border-blue-300 hover:bg-blue-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
                        >
                          <FiUpload size={20} />
                          <span>Upload Icon Image</span>
                        </button>
                      )}
                      <div className="mt-1">
                        {errors[`infoIcon${idx}`] && (
                          <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                            {errors[`infoIcon${idx}`]}
                          </span>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => deleteInfoData(idx)}
                      className="self-end flex items-center space-x-2 text-red-600 font-poppins p-3 border-red-500 border-2 rounded hover:border-red-300 hover:bg-red-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
                    >
                      <MdDelete size={20} />
                      <span>Delete</span>
                    </button>
                  </AccordionDetails>
                </Accordion>
              ))}
              <button
                onClick={addNewInfoData}
                className="mb-2 mt-3 w-full sm:w-[50%] md:w-[30%] lg:w-[20%] xl:w-[15%] px-2 sm:px-4 py-1.5 sm:py-2 text-sm sm:text-base bg-purple-600 text-white rounded hover:bg-purple-700"
              >
                Add Info
              </button>
            </>
          )}

          <div className="flex items-center gap-4 mt-3">
            <label className="font-medium font-poppins text-gray-600 block">
              Profile Management :
            </label>
            <ReactSwitch
              checked={aboutUs.profileManagementToggle}
              onChange={(isChecked) => {
                SetAboutUs((prevState) => ({
                  ...prevState,
                  profileManagementToggle: isChecked,
                }));
              }}
            />
          </div>
          {aboutUs.profileManagementToggle && (
            <>
              {aboutUsProfile.map((profile, idx) => (
                <Accordion key={idx}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="font-medium">
                      Profile {idx + 1}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="flex justify-between items-start">
                    <div className="w-2/3 space-y-6">
                      <label className="font-medium font-poppins text-gray-600 block">
                        Profile Name
                      </label>
                      <input
                        value={profile.name}
                        onChange={(e) => {
                          IsSavedInputs();
                          updateProfile(idx, "name", e.target.value);
                        }}
                        type="text"
                        placeholder="Name"
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      <div className="mt-1">
                        {errors[`profileName${idx}`] && (
                          <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                            {errors[`profileName${idx}`]}
                          </span>
                        )}
                      </div>
                      <label className="font-medium font-poppins text-gray-600 block">
                        Profile Designation
                      </label>
                      <input
                        value={profile.designation}
                        onChange={(e) => {
                          IsSavedInputs();
                          updateProfile(idx, "designation", e.target.value);
                        }}
                        type="text"
                        placeholder="Designation"
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                      <div className="mt-1">
                        {errors[`profileDesignation${idx}`] && (
                          <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                            {errors[`profileDesignation${idx}`]}
                          </span>
                        )}
                      </div>
                      <label className="font-medium font-poppins text-gray-600 block">
                        Profile Image
                      </label>
                      {profile.profileImage ? (
                        <div className="relative mt-2 inline-block">
                          <img
                            src={profile.profileImage}
                            alt="Selected Design"
                            className="w-40 h-40 object-cover rounded-full  shadow-md"
                          />
                          <button
                            onClick={() => {
                              IsSavedInputs();
                              const updatedProfiles = [...aboutUsProfile];
                              updatedProfiles[idx].profileImage = null;
                              setAboutUsProfile(updatedProfiles);
                            }}
                            className="absolute top-6 right-4 p-1 bg-gray-100 text-red-600 font-bold rounded-full hover:bg-red-200 focus:ring focus:ring-red-200 transform translate-x-1/2 -translate-y-1/2"
                          >
                            <AiOutlineClose size={15} />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            IsSavedInputs();
                            setCurrentProfileDataIndex(idx);
                            setProfileModalIsOpen(true);
                          }}
                          className="flex items-center space-x-2 text-blue-600 font-poppins p-3 border-blue-500 border-2 rounded hover:border-blue-300 hover:bg-blue-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
                        >
                          <FiUpload size={20} />
                          <span>Upload Profile Image</span>
                        </button>
                      )}
                      <div className="mt-1">
                        {errors[`profileImage${idx}`] && (
                          <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                            {errors[`profileImage${idx}`]}
                          </span>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => deleteProfile(idx)}
                      className="self-end flex items-center space-x-2 text-red-600 font-poppins p-3 border-red-500 border-2 rounded hover:border-red-300 hover:bg-red-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
                    >
                      <MdDelete size={20} />
                      <span>Delete</span>
                    </button>
                  </AccordionDetails>
                </Accordion>
              ))}

              <button
                onClick={addNewProfile}
                className="mb-2 mt-3 w-full sm:w-[50%] md:w-[30%] lg:w-[20%] xl:w-[15%] px-2 sm:px-4 py-1.5 sm:py-2 text-sm sm:text-base bg-sky-600 text-white rounded hover:bg-sky-700"
              >
                Add Profile
              </button>
            </>
          )}
          <div className="">
            <div className="flex items-center  my-4">
              <label className="mr-4 font-medium text-gray-600">History:</label>
              <ReactSwitch
                checked={aboutUs.historyToggle}
                onChange={(isChecked) => {
                  SetAboutUs((prevState) => ({
                    ...prevState,
                    historyToggle: isChecked,
                  }));
                }}
              />
            </div>

            {/* Conditionally render History Description text area */}
            {aboutUs.historyToggle && (
              <>
                <label className="font-medium font-poppins py-3 text-gray-600 block">
                  History Description
                </label>
                <SunEditor
                  setDefaultStyle="font-family: poppins; font-size: 15px;"
                  setOptions={{
                    height: 400,
                    buttonList: [
                      [
                        "font",
                        "fontSize",
                        "subscript",
                        "superscript",
                        "bold",
                        "italic",
                        "underline",
                      ], // Added 'font'
                      ["fontColor", "hiliteColor", "outdent", "indent"],
                      ["codeView", "undo", "redo", "align", "list", "table"], // Added 'codeView'
                      ["fullScreen", "preview"],
                    ],
                    // imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                    // To define custom fonts:
                    font: [
                      "Arial",
                      "Calibri",
                      "Comic Sans",
                      "Courier",
                      "Poppins",
                      "Times New Roman",
                    ],
                    // You can add other options according to your needs
                  }}
                  setContents={aboutUs.historyDescription}
                  onChange={(content) => {
                    // Compare the current content with the previous content
                    if (prevDescriptionRef2.current !== content) {
                      IsSavedInputs();
                    }
                    prevDescriptionRef2.current = content; // Update the ref with the new content
                    SetAboutUs({ ...aboutUs, historyDescription: content });
                  }}
                />
              </>
            )}
            <div className="mt-3">
              {errors.historyDescription && (
                <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                  {errors.historyDescription}
                </span>
              )}
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className={`bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
              Object.keys(errors).length > 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={Object.keys(errors).length > 0}
          >
            {Object.keys(errors).length > 0
              ? "Fill the required fields"
              : dataSubmitted
              ? "Update"
              : "Save"}
          </button>
        </AccordionDetails>

        {/* Adding the ReactSwitch for History Toggle */}
      </Accordion>

      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen2(false)}
        style={{
          overlay: {
            zIndex: 1000, // Ensure this value is higher than the z-index of SunEditor
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        <Gallery
        dimension="1:1" 
          onImageSelect={(image) =>
            handleImageSelect1(image, currentInfoDataIndex)
          }
          closeModal={() => setModalIsOpen2(false)}
        />
      </Modal>
      <Modal
        isOpen={profileModalIsOpen}
        onRequestClose={() => setProfileModalIsOpen(false)}
        style={{
          overlay: {
            zIndex: 1000, // Ensure this value is higher than the z-index of SunEditor
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        <Gallery
        dimension="1:1" 
          onImageSelect={(image) =>
            handleProfileImageSelect(image, currentProfileDataIndex)
          }
          closeModal={() => setProfileModalIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default AboutUs;

