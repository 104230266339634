import React from 'react'

export const TemplateBlockSeventeen = ({ templateBlockClick, activeBlock }) => {
  return (
    <div className='flex flex-col w-full text-center'>
      <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4 min-h-[270px] ${activeBlock === 17 ? "border-red-500 border-2" : ""}`}
        onClick={() => templateBlockClick(17,3)}
      >
        <div className="w-full flex justify-evenly gap-4 h-full">
          <div className='w-1/2 flex flex-col h-full gap-4'>
            <div className="h-full border-2 border-blue-500"></div>
            <div className="h-full border-2 border-blue-500"></div>
          </div>
          <div className='w-1/2 h-full'>
            <div className="h-full border-2 border-blue-500"></div>
          </div>
        </div>
      </div>
      <p className='font-bold'>Type 18</p>
    </div>
  )
}
