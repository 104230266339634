import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Gallery from './Components/Gallery';
import Upload from './Components/Upload';
import DefaultGallery from './Components/DefaultGallery';
import ImageOverlay from './Components/OverlayImage';
import GoDaddy from './Components/DomainManagement/GoDaddy';
import Tabs from './Components/Tabs';
import SSL from "./Components/DomainManagement/SslCertificate";
import { TokenProvider } from './Context/TokenContext'; // Import TokenProvider
import useTokenFromURL from './Context/useTokenFromURL'; // Import custom hook
import setupAxios from './Axios/useAxios';
import PaymentSuccess from './Components/DomainManagement/PaymentSuccess';
import PaymentFailure from './Components/DomainManagement/PaymentFailure';
import ExistingDomain from './Components/DomainManagement/ExistingDomain';
import ImageCropper from './Components/ImageCropper';
import ThemePreview from './Components/ThemeManagement/ThemeGroups/Antique';
import ErrorPage from './Components/ErrorPage';


function DefaultComponent() {
  return (
    <div>
      <h1>Page not found</h1>
      <a href="/">Go to Home</a>
    </div>
  );
}

function InnerApp() {

  useTokenFromURL();
  setupAxios();

  return (
    <Router>
      <Routes>
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/default-gallery" element={<DefaultGallery />} />
        <Route path="/image-overlay" element={<ImageOverlay />} />
        <Route path="/godaddy" element={<GoDaddy />} />
        <Route path="/ssl" element={<SSL />} />
        <Route path="/paymentSuccess/:id" element={<PaymentSuccess />} />
        <Route path="/paymentfailure/:id" element={<PaymentFailure />} />
        <Route path="/existing" element={<ExistingDomain />} />
        <Route path="/image" element={<ImageCropper />} />
        <Route path="/" element={<Tabs />} />
        <Route path="*" element={<DefaultComponent />} /> {/* fallback route */}
        <Route path="/test" element={<ThemePreview />} />
        {/* error route added - 16/08/2024 */}
        <Route path="/errorPage" element={<ErrorPage />} />

      </Routes>
    </Router>
  );
}

function App() {
  return (
    <TokenProvider>
      <InnerApp />
    </TokenProvider>
  );
}

export default App;

