import React from "react";
import BannerCustomization from "./Banner/BannerCustomization";
import CategorySliderCustomization from "./CategorySldier/CategorySlider";
import CmsBlockCustomization from "./CMSBlock/CmsBlock";
import CollageCustomization from "./Collage/CollageCustomization";
import InfoSliderCustomization from "./Information Slider/InformationSlider";
import ProductSliderCustomization from "./ProductSlider/ProductSlider";
import SubscriptionCustomization from "./Subscription/Subscription";
// Import BASE_URL from config.js

const WidgetDetails = ({
  activeWidget,
  clickedWidgetId,
  setToggleBanner,
  toggleBanner,
  refresh,
  setBannerDataSubmit,
  fetchWidgetOrder,
  widgetName,
  setHasInteracted,
  setUnsavedChanges,
  unsavedChanges,
  hasInteracted,
  imageCountForSelectedTemplate,
  setImageCountForSelectedTemplate,
  widgetActiveId,
}) => {
  const widgetType = activeWidget ? activeWidget.match(/^\D+/)[0].trim() : "";

  const renderContent = () => {
    if (!activeWidget) return null; // Add guard clause if activeWidget is not set

    switch (widgetType) {
      case "Banner":
        return toggleBanner ? (
          <BannerCustomization
            widgetType={widgetType}
            widgetName={widgetName}
            widgetId={clickedWidgetId}
            setToggleBanner={setToggleBanner}
            hasInteracted={hasInteracted}
            setHasInteracted={setHasInteracted}
            setBannerDataSubmit={setBannerDataSubmit}
            setUnsavedChanges={setUnsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            widgetActiveId={widgetActiveId}
          />
        ) : null;
      case "Collage":
        return (
          <CollageCustomization
            widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}
            imageCountForSelectedTemplate={imageCountForSelectedTemplate}
            setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}
          />
        );
      case "Info Slider":
        return (
          <InfoSliderCustomization
            widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}
          />
        );
      case "Product Slider":
        return (
          <ProductSliderCustomization
            widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}
          />
        );
      case "Category Slider":
        return (
          <CategorySliderCustomization
            widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}
          />
        );
      case "Subscription":
        return (
          <SubscriptionCustomization
            widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}
          />
        );
      case "Cms Custom":
        return (
          <CmsBlockCustomization
            widgetType={widgetType}
            unsavedChanges={unsavedChanges}
            fetchWidgetOrder={fetchWidgetOrder}
            setUnsavedChanges={setUnsavedChanges}
            setHasInteracted={setHasInteracted}
            hasInteracted={hasInteracted}
            refresh={refresh}
            widgetId={clickedWidgetId}
          />
        );
      default:
        return (
          <BannerCustomization
            widgetType={widgetType}
            widgetName={widgetName}
          />
        );
    }
  };

  return renderContent();
};

export default WidgetDetails;
