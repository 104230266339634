import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FiUpload } from "react-icons/fi";
import Gallery from "../Gallery";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import SunEditor from "suneditor-react";
import Swal from "sweetalert2";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import { BASE_URL } from '../../config';
import {MdFileUpload } from "react-icons/md";
import { useToken } from '../../Context/TokenContext';

const REACT_APP_BASE_URL = BASE_URL;

/**
 * Auth Component - Responsible for authentication page settings and management
 *
 * @param {string} activeAccordion - Currently active accordion identifier
 * @param {function} onToggle - Function to handle toggling of accordion
 * @param {function} IsSavedInputs - A function to indicate if inputs were saved
 *
 * @returns {JSX.Element} - Rendered component
 */

const Auth = ({ activeAccordion, onToggle, IsSavedInputs }) => {
  // Hooks to handle URL token and API requests
  useTokenFromURL();
  useAxios();
  const {jboId} = useToken();

  // State for website details
  const [websiteInfo, setWebsiteInfo] = useState({
    auth_page_id: null,
    name: "",
    description: "",
    image: null,
  });

  // Ref to keep track of the previous description
  const prevDescriptionRef = useRef(websiteInfo.description);

  // State for error messages, submission status, default description and modal visibility

  const [errors, setErrors] = useState({});
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [defaultDescription, setDefaultDescription] = useState("<p><br></p>");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  /**
   * Handler for accordion changes
   */

  const handleAccordionChange = () => {
    if (activeAccordion === "AuthAccordian") {
      onToggle && onToggle(null); // close the current accordion if it's 'AuthAccordian'.
    } else {
      onToggle && onToggle("AuthAccordian"); // open the 'AuthAccordian' if another accordion is currently active.
    }
  };

  /**
   * Handler for image selection
   *
   * @param {File} image - Selected image file
   */

  const handleImageSelect = (image) => {
    setWebsiteInfo((prevState) => ({ ...prevState, image }));
    setModalIsOpen(false);
    IsSavedInputs(); // Call the function here after image selection
  };

  /**
   * Fetches authentication page details from the API
   */

  const fetchAuthPageDetails = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/auth-page`);
      if (response.data) {
        setDataSubmitted(true);
      }
      // Assuming that the API always returns a single object in the array
      if (response.data.length > 0) {
        const data = response.data[0];

        // Update the state with the fetched data
        setWebsiteInfo({
          name: data.website_name,
          description: data.website_description,
          image: data.design_image_url,
        });
        prevDescriptionRef.current = data.website_description;
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Auth Page Not found"
      ) {
        setDataSubmitted(false);
      }
      console.error("Error fetching auth page details:", error);
    }
  };

  // Fetch data when the component mounts or data is submitted
  useEffect(() => {
    fetchAuthPageDetails();
  }, [dataSubmitted]);

  /**
   * Submits the website information to the API
   */
  const handleSubmit = async () => {
    // Convert the websiteInfo to match the API's expected payload structure
    const payload = {
      website_name: websiteInfo.name,
      website_description: websiteInfo.description,
      design_image_url: websiteInfo.image,
    };

    try {
      // Send data to the backend
      const response = await axios.patch(
        `${REACT_APP_BASE_URL}/auth-page`,
        payload
      );

      // Handle the response, for example:
      if (dataSubmitted) {
        Swal.fire({
          title: "Success!",
          text: "Datas Updated successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Success!",
          text: "Datas saved successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
      fetchAuthPageDetails();
    } catch (error) {
      console.error("Error saving the data:", error);
    }
  };

  /**
   * Validates the form inputs and updates the error messages state
   */
  const validateForm = () => {
    let formErrors = {};
    if (!websiteInfo.name || !websiteInfo.name?.trim()) {
      formErrors.name = "Name is required!";
    }

    // Check if websiteInfo.description is provided
    if (
      !websiteInfo.description ||
      !websiteInfo.description?.trim() ||
      websiteInfo.description === defaultDescription
    ) {
      formErrors.description = "Description is required!";
    }

    // Check if websiteInfo.image is selected
    if (!websiteInfo.image) {
      formErrors.image = "Image is required!";
    }

    setErrors(formErrors);
  };

  // Validate form whenever websiteInfo changes
  useEffect(() => {
    validateForm();
    // IsSavedInputs()
  }, [websiteInfo]);
  return (
    <div className="px-2">
      <Accordion
        expanded={activeAccordion === "AuthAccordian"}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{ fontFamily: "poppins" }}
            className="font-medium font-poppins"
          >
            {" "}
            Login / Register Page{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full space-y-6">
            <div>
              <label className="font-medium font-poppins py-4 text-gray-600 block">
                Website Name
              </label>
              <input
                value={websiteInfo.name}
                onChange={(e) => {
                  setWebsiteInfo((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                  IsSavedInputs(); // Call the function here after updating the name
                }}
                type="text"
                placeholder="Website Name"
                className="mt-1 mb-2  focus:ring-blue-500 focus:border-blue-500 block w-2/3 sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            {errors.name && (
              <span className=" text-red-700   bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                {errors.name}
              </span>
            )}

            <div>
              <label className="font-medium font-poppins  py-4 text-gray-600 block">
                Description
              </label>
              <SunEditor
                setDefaultStyle="font-family: poppins; font-size: 15px;"
                style={{ width: "100%" }}
                setOptions={{
                  height: 400,
                  buttonList: [
                    [
                      "font",
                      "fontSize",
                      "subscript",
                      "superscript",
                      "bold",
                      "italic",
                      "underline",
                    ], // Added 'font'
                    ["fontColor", "hiliteColor", "outdent", "indent"],
                    ["codeView", "undo", "redo", "align", "list", "table"], // Added 'codeView'
                    ["fullScreen", "preview"],
                  ],
                  // imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                  // To define custom fonts:
                  font: [
                    "Arial",
                    "Calibri",
                    "Comic Sans",
                    "Courier",
                    "Poppins",
                    "Times New Roman",
                  ],
                  // You can add other options according to your needs
                }}
                setContents={websiteInfo.description}
                onChange={(e) => {
                  // Compare the current content with the previous content
                  if (prevDescriptionRef.current !== e) {
                    IsSavedInputs();
                  }
                  prevDescriptionRef.current = e; // Update the ref with the new content

                  setWebsiteInfo((prevState) => ({
                    ...prevState,
                    description: e,
                  }));
                }}
                // onChange={(content) =>
                //   setWebsiteInfo({ ...websiteInfo, description: content })
                // }
              />
              <div className="mt-3">
                {errors.description && (
                  <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                    {errors.description}
                  </span>
                )}
              </div>
            </div>
            <label className="font-medium font-poppins text-gray-600 block">
              Image
            </label>
            {websiteInfo.image ? (
              <div className="relative mt-2 inline-block ">
                <img
                  src={websiteInfo.image}
                  alt="Selected Design"
                  className="w-40 h-40 object-cover rounded shadow-md"
                />
                <button
                  onClick={() => {
                    setWebsiteInfo((prevState) => ({
                      ...prevState,
                      image: null,
                    }));
                    IsSavedInputs();
                  }}
                  className="absolute top-5  right-5  p-1 bg-gray-100 text-red-600 font-bold rounded-full hover:bg-red-200 focus:ring focus:ring-red-200 transform translate-x-1/2 -translate-y-1/2"
                >
                  <AiOutlineClose size={15} />
                </button>
                <button onClick={() => setModalIsOpen(true)} 
                  className="absolute top-11  right-5 p-1 bg-gray-100 text-blue-600 font-bold rounded-full hover:bg-red-200 focus:ring focus:ring-red-200 transform translate-x-1/2 -translate-y-1/2">
                  <MdFileUpload />
                </button>

              </div>
            ) : (
              <button
                onClick={() => setModalIsOpen(true)}
                className="flex items-center space-x-2 text-blue-600 font-poppins p-3 border-blue-500 border-2  rounded  hover:border-blue-300 hover:bg-blue-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
              >
                <FiUpload size={20} />
                <span>Upload Design Image</span>
              </button>
            )}
          </div>
          <div className="mt-3">
            {errors.image && (
              <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                {errors.image}
              </span>
            )}
          </div>
          <button
            onClick={handleSubmit}
            className={`bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
              Object.keys(errors).length > 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={Object.keys(errors).length > 0}
          >
            {/* {dataSubmitted ? "Update" : "Save"} */}

            {Object.keys(errors).length > 0
              ? "Fill the required fields"
              : dataSubmitted
              ? "Update"
              : "Save"}
          </button>
        </AccordionDetails>
      </Accordion>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            zIndex: 1000, // Ensure this value is higher than the z-index of SunEditor
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        <Gallery dimension="2:3" onImageSelect={handleImageSelect} closeModal={() => setModalIsOpen(false)}/>
      </Modal>
    </div>
  );
};

export default Auth;

