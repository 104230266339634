import React, { useState } from "react";
import { TemplateBlockFour } from "./moreCollageTemplate/templateBlock/templateBlockFour";
import { TemplateBlockFive } from "./moreCollageTemplate/templateBlock/templateBlockFive";
import { TemplateBlockSix } from "./moreCollageTemplate/templateBlock/templateBlockSix";
import { TemplateBlockSeven } from "./moreCollageTemplate/templateBlock/templateBlockSeven";
import { TemplateBlockEight } from "./moreCollageTemplate/templateBlock/templateBlockEight";
import { TemplateBlockNine } from "./moreCollageTemplate/templateBlock/templateBlockNine";
import { TemplateBlockTen } from "./moreCollageTemplate/templateBlock/templateBlockTen";
import { TemplateBlockEleven } from "./moreCollageTemplate/templateBlock/templateBlockEleven";
import { TemplateBlockTwelve } from "./moreCollageTemplate/templateBlock/templateBlockTwelve";
import { TemplateBlockThirteen } from "./moreCollageTemplate/templateBlock/templateBlockThirteen";
import { TemplateBlockFourteen } from "./moreCollageTemplate/templateBlock/templateBlockFourteen";
import { TemplateBlockFifteen } from "./moreCollageTemplate/templateBlock/templateBlockFifteen";
import { TemplateBlockSixteen } from "./moreCollageTemplate/templateBlock/templateBlockSixteen";
import { TemplateBlockSeventeen } from "./moreCollageTemplate/templateBlock/templateBlockSeventeen";
import { TemplateBlockEighteen } from "./moreCollageTemplate/templateBlock/templateBlockEighteen";
import { TemplateBlockNineteen } from "./moreCollageTemplate/templateBlock/templateBlockNineteen";
import { TemplateBlockTwenty } from "./moreCollageTemplate/templateBlock/templateBlockTwenty";
import { TemplateBlockTwentyOne } from "./moreCollageTemplate/templateBlock/templateBlockTwentyOne";
import { TemplateBlockTwentyTwo } from "./moreCollageTemplate/templateBlock/templateBlockTwentyTwo";
import { TemplateBlockTwentyThree } from "./moreCollageTemplate/templateBlock/templateBlockTwentyThree";
import { TemplateBlockTwentyFour } from "./moreCollageTemplate/templateBlock/templateBlockTwentyFour";
import { TemplateBlockZero } from "./moreCollageTemplate/templateBlock/templateBlockZero";
import { TemplateBlockOne } from "./moreCollageTemplate/templateBlock/templateBlockOne";
import { TemplateBlockTwo } from "./moreCollageTemplate/templateBlock/templateBlockTwo";
import { TemplateBlockThree } from "./moreCollageTemplate/templateBlock/templateBlockThree";
import { IoMdCloseCircle } from "react-icons/io";

const MoreCollageTemplates = ({ handleBlockClick, activeBlock, setTemplatesModelOpen,setImageCountForSelectedTemplate }) => {

  const templateBlockClick = (blockNumber,imageCount) => {
    handleBlockClick(blockNumber);
    setTemplatesModelOpen(false)
    setImageCountForSelectedTemplate(imageCount)
  }

  const [filterByCount, setFilterByCount] = useState("all");

  const handleFilter = (count) => {
    return count == filterByCount || filterByCount == 'all' ? true : false
  }

  return (
    <div className="flex flex-col gap-4 overflow-hidden">
      <div className="w-full flex justify-end absolute right-2 top-3">
        <IoMdCloseCircle onClick={() => setTemplatesModelOpen(false)} className="cursor-pointer" size={30} />
      </div>

      <h1 className="text-lg font-poppins text-center text-gray-600">
        Select Collage Structure
      </h1>

      <div className="w-full flex justify-end">
        <div className="w-40 ml-0">
          <label
            htmlFor="aspectRatio"
            className="font-medium text-gray-700"
          >
            Number of Images
          </label>
          <select
            id="count"
            name="count"
            value={filterByCount}
            onChange={(e) => setFilterByCount(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          >
            <option value={"all"}>Any</option>
            <option value={1}>One</option>
            <option value={2}>Two</option>
            <option value={3}>Three</option>
            <option value={4}>Four</option>
            <option value={5}>Five</option>
            <option value={6}>Six</option>
            <option value={7}>Seven</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {handleFilter('3') && <TemplateBlockFive activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('4') && <TemplateBlockSix activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && <TemplateBlockSeven activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('2') && <TemplateBlockEight activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('3') && <TemplateBlockNine activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('3') && <TemplateBlockTen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && <TemplateBlockTwelve activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && <TemplateBlockThirteen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && <TemplateBlockFourteen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('6') && <TemplateBlockFifteen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('3') && <TemplateBlockSixteen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('3') && <TemplateBlockSeventeen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('2') && <TemplateBlockEighteen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('4') && <TemplateBlockNineteen activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('4') && <TemplateBlockTwenty activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('4') && <TemplateBlockTwentyOne activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && <TemplateBlockTwentyTwo activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && <TemplateBlockTwentyThree activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && <TemplateBlockTwentyFour activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('1') && filterByCount !== 'all' && <TemplateBlockZero activeBlock={activeBlock} templateBlockClick={templateBlockClick} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate} />}
        {handleFilter('4') && filterByCount !== 'all' && <TemplateBlockOne activeBlock={activeBlock} templateBlockClick={templateBlockClick} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}/>}
        {handleFilter('3') && filterByCount !== 'all' && <TemplateBlockThree activeBlock={activeBlock} templateBlockClick={templateBlockClick} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}/>}
        {handleFilter('3') && <TemplateBlockEleven activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
        {handleFilter('5') && filterByCount !== 'all' && <TemplateBlockTwo activeBlock={activeBlock} templateBlockClick={templateBlockClick} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}/>}
        {handleFilter('7') && <TemplateBlockFour activeBlock={activeBlock} templateBlockClick={templateBlockClick} />}
      </div>
    </div>
  );
};

export default MoreCollageTemplates;
