import React from 'react'

export const TemplateBlockTwo = ({ templateBlockClick, activeBlock,setImageCountForSelectedTemplate }) => {
  return (
    <div className='flex flex-col w-full text-center'>
      <div
        onClick={() => {templateBlockClick(2);setImageCountForSelectedTemplate(5)}}
        className={`w-full p-4 bg-gray-200 rounded-lg flex gap-3 ${activeBlock === 2 ? "border-red-500" : ""
          } border-2 h-fit`}
      >
        <div className="w-2/3 h-45 rounded-sm border-2 border-blue-500"></div>
        <div className="w-1/3 flex flex-col justify-between gap-3">
          <div className="h-12 border-2 rounded-sm border-blue-500 mb-1"></div>
          <div className="h-12 border-2 rounded-sm border-blue-500 mb-1"></div>
        </div>
        <div className="w-1/3 flex flex-col justify-between gap-3">
          <div className="h-12 border-2 rounded-sm border-blue-500 mb-1"></div>
          <div className="h-12 border-2 rounded-sm border-blue-500 mb-1"></div>
        </div>
      </div>
      <p className='font-bold'>Type 3</p>
    </div>
  )
}


