import React from 'react'
import { RenderCommonUpload } from '../renderCommonUpload'

export const CollageBlockTwentyThree = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
  return (
    <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4`}>
        <div className="w-full flex justify-evenly gap-4 ">
        <div className="w-1/3 border-2 border-blue-500 h-[151px]">
              <RenderCommonUpload buttonIndex={0} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:6"}/>
          </div>
        <div className="w-1/3 border-2 border-blue-500 h-[151px]">
              <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:6"}/>
          </div>
        <div className="w-1/3 border-2 border-blue-500 h-[151px]">
              <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:6"}/>
          </div>
        </div>
        <div className="w-full flex justify-evenly gap-4 ">
        <div className="w-1/3 border-2 border-blue-500 h-[151px]">
              <RenderCommonUpload buttonIndex={3} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:6"}/>
          </div>
        <div className="w-2/3 border-2 border-blue-500 h-[154px]">
              <RenderCommonUpload buttonIndex={4} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"15.1:6"}/>
          </div>
        </div>
        
    </div>
  )
}


