import { CollageBlockEight } from "./moreCollageTemplate/customizationBlocks/collageBlockEight";
import { CollageBlockEighteen } from "./moreCollageTemplate/customizationBlocks/collageBlockEighteen";
import { CollageBlockEleven } from "./moreCollageTemplate/customizationBlocks/collageBlockEleven";
import { CollageBlockFifteen } from "./moreCollageTemplate/customizationBlocks/collageBlockFifteen";
import { CollageBlockFive } from "./moreCollageTemplate/customizationBlocks/collageBlockFive";
import { CollageBlockFour } from "./moreCollageTemplate/customizationBlocks/collageBlockFour";
import { CollageBlockFourteen } from "./moreCollageTemplate/customizationBlocks/collageBlockFourteen";
import { CollageBlockNine } from "./moreCollageTemplate/customizationBlocks/collageBlockNine";
import { CollageBlockNineteen } from "./moreCollageTemplate/customizationBlocks/collageBlockNineteen";
import { CollageBlockOne } from "./moreCollageTemplate/customizationBlocks/collageBlockOne";
import { CollageBlockSeven } from "./moreCollageTemplate/customizationBlocks/collageBlockSeven";
import { CollageBlockSeventeen } from "./moreCollageTemplate/customizationBlocks/collageBlockSeventeen";
import { CollageBlockSix } from "./moreCollageTemplate/customizationBlocks/collageBlockSix";
import { CollageBlockSixteen } from "./moreCollageTemplate/customizationBlocks/collageBlockSixteen";
import { CollageBlockTen } from "./moreCollageTemplate/customizationBlocks/collageBlockTen";
import { CollageBlockThirteen } from "./moreCollageTemplate/customizationBlocks/collageBlockThirteen";
import { CollageBlockThree } from "./moreCollageTemplate/customizationBlocks/collageBlockThree";
import { CollageBlockTwelve } from "./moreCollageTemplate/customizationBlocks/collageBlockTwelve";
import { CollageBlockTwenty } from "./moreCollageTemplate/customizationBlocks/collageBlockTwenty";
import { CollageBlockTwentyFour } from "./moreCollageTemplate/customizationBlocks/collageBlockTwentyFour";
import { CollageBlockTwentyOne } from "./moreCollageTemplate/customizationBlocks/collageBlockTwentyOne";
import { CollageBlockTwentyThree } from "./moreCollageTemplate/customizationBlocks/collageBlockTwentyThree";
import { CollageBlockTwentyTwo } from "./moreCollageTemplate/customizationBlocks/collageBlockTwentyTwo";
import { CollageBlockTwo } from "./moreCollageTemplate/customizationBlocks/collageBlockTwo";
import { CollageBlockZero } from "./moreCollageTemplate/customizationBlocks/collageBlockZero";

const SelectedTemplate = ({
  SelectedActiveBlock,
  activeBlock,
  IsSavedInputs,
  blockImages,
  setBlockImages,
}) => {
  return (
    <div className="mt-4" >
      {SelectedActiveBlock !== null ? (
        <h2 className="text-md font-poppins text-center text-gray-600 mb-3">
          Selected Block Customization
        </h2>
      ) : (
        false
      )}
      {activeBlock === 0 && (<CollageBlockZero IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 1 && (<CollageBlockOne IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 2 && (<CollageBlockTwo IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 3 && (<CollageBlockThree IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 4 && (<CollageBlockFour IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 5 && (<CollageBlockFive IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 6 && (<CollageBlockSix IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 7 && (<CollageBlockSeven IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 8 && (<CollageBlockEight IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 9 && (<CollageBlockNine IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 10 && (<CollageBlockTen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 11 && (<CollageBlockEleven IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 12 && (<CollageBlockTwelve IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 13 && (<CollageBlockThirteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 14 && (<CollageBlockFourteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 15 && (<CollageBlockFifteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 16 && (<CollageBlockSixteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 17 && (<CollageBlockSeventeen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 18 && (<CollageBlockEighteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 19 && (<CollageBlockNineteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 20 && (<CollageBlockTwenty IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 21 && (<CollageBlockTwentyOne IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 22 && (<CollageBlockTwentyTwo IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 23 && (<CollageBlockTwentyThree IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
      {activeBlock === 24 && (<CollageBlockTwentyFour IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} />)}
    </div>
  );
};

export default SelectedTemplate;






// import react, { useState, useEffect } from "react";
// import Modal from "react-modal";
// import Gallery from "../../Gallery";
// import { FiLink, FiUpload } from "react-icons/fi";
// import { CgClose } from "react-icons/cg";
// import { MdFileUpload } from "react-icons/md";
// import ImageLinkModal from "./ImageLinkModal";

// const SelectedTemplate = ({
//   SelectedactiveBlock,
//   renderUploadButton1,
//   modalIsOpen,
//   activeBlock,
//   customModalStyles,
//   renderUploadButton2,
//   renderUploadButton3,
//   renderUploadButton4,
//   // renderUploadButton5,
//   setModalIsOpen,
//   handleImageSelect1,
//   handleImageSelect2,
//   reqDimension,
//   handleImageSelect3,
//   handleImageSelect4,
//   blockFiveImages,
//   setBlockFiveImages,
//   setMenuLinkInput5,
//   menuLinkInput5,
//   // setShowModal5
//   // handleImageSelect5,
// }) => {
//   const blockButtonsMapping = {
//     0: [0, 1, 2, 3],
//     1: [0, 1],
//     2: [0, 1, 2, 3, 4],
//     3: [0, 1, 2],
//     4: [0, 1, 2, 3, 4],
//   };
//   const [blockFiveDimension, setBlockFiveDimension] = useState("");
//   const [selectedButton, setSelectedButton] = useState(null);

//   const [showModal5, setShowModal5] = useState(null);
//   const [currentEditingImageIndex, setCurrentEditingImageIndex] =
//     useState(null);
//   const handleBlockFiveUploadClick = (buttonIndex) => {
//     if (buttonIndex === 1) {
//       setBlockFiveDimension("3.7:3");
//     } else {
//       setBlockFiveDimension("2:2");
//     }
//     setSelectedButton(buttonIndex);
//     setModalIsOpen(true);
//   };

//   const handleImageSelect5 = (image) => {
//     // IsSavedInputs();
//     setBlockFiveImages((prevImages) => {
//       const updatedImages = [...prevImages];
//       updatedImages[selectedButton] = { image_url: image, image_link: "" };
//       return updatedImages;
//     });
//     setModalIsOpen(false);
//   };

//   const renderUploadButton5 = (buttonIndex) => {
//     const handleDeleteClick = (index) => {
//       // IsSavedInputs();
//       const newImages = [...blockFiveImages];
//       newImages[index] = { image_url: null, image_link: null };
//       setBlockFiveImages(newImages);
//     };

//     const handleLinkClick = (index) => {
//       setCurrentEditingImageIndex(index);
//       setMenuLinkInput5(blockFiveImages[index]?.image_link || "");
//       setShowModal5(true);
//     };

//     const closeModal = () => {
//       setShowModal5(false);
//     };

//     const handleModalSubmit = (e) => {
//       e.preventDefault();
//       const newImages = [...blockFiveImages];

//       if (currentEditingImageIndex !== null) {
//         const currentImage = newImages[currentEditingImageIndex];

//         if (typeof currentImage === "string") {
//           newImages[currentEditingImageIndex] = {
//             image_url: currentImage,
//             image_link: menuLinkInput5,
//           };
//         } else {
//           currentImage.image_link = menuLinkInput5;
//         }
//       }
//       setBlockFiveImages(newImages);
//       closeModal();
//     };

//     return (
//       <div>
//         {blockFiveImages[buttonIndex].image_url ? (
//           <>
//             <img
//               src={
//                 blockFiveImages[buttonIndex]?.image_url ||
//                 blockFiveImages[buttonIndex]
//               }
//               alt="Selected"
//               style={{ width: "100%", height: "100%", objectFit: "cover" }}
//             />
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 e.stopPropagation();
//                 handleLinkClick(buttonIndex);
//               }}
//               style={{
//                 position: "absolute",
//                 top: "3rem",
//                 right: "0",
//                 backgroundColor: blockFiveImages[buttonIndex]?.image_link
//                   ? "green"
//                   : "blue",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 border: "none",
//                 padding: "6px",
//                 transform: "translateY(-50%)",
//               }}
//             >
//               <FiLink size="1rem" color="white" />
//             </button>
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 e.stopPropagation();
//                 handleDeleteClick(buttonIndex);
//               }}
//               style={{
//                 position: "absolute",
//                 top: "0",
//                 right: "0",
//                 backgroundColor: "red",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 border: "none",
//                 padding: "6px",
//               }}
//             >
//               <CgClose size="1rem" color="white" />
//             </button>
//             <div
//               onClick={(e) => {
//                 e.preventDefault();
//                 handleBlockFiveUploadClick(buttonIndex);
//               }}
//               style={{
//                 position: "absolute",
//                 top: "4.3rem",
//                 right: "0",
//                 backgroundColor: "blue",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 border: "none",
//                 padding: "6px",
//               }}
//             >
//               <MdFileUpload size="1rem" color="white" />
//             </div>
//           </>
//         ) : (
//           <button
//             onClick={(e) => {
//               e.preventDefault();
//               handleBlockFiveUploadClick(buttonIndex);
//             }}
//             style={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//             }}
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
//           >
//             <FiUpload size="1.5em" />
//           </button>
//         )}
//         <ImageLinkModal
//           showModal5={showModal5}
//           currentEditingImageIndex={currentEditingImageIndex}
//           closeModal={closeModal}
//           menuLinkInput5={menuLinkInput5}
//           handleModalSubmit={handleModalSubmit}
//           setMenuLinkInput5={setMenuLinkInput5}
//         />
//       </div>
//     );
//   };

//   return (
//     <div className="mt-4">
//       {SelectedactiveBlock !== null ? (
//         <h2 className="text-md font-poppins text-center text-gray-600 mb-3">
//           Selected Block Customization
//         </h2>
//       ) : (
//         false
//       )}
//       {activeBlock === 0 && (
//         <div className={`w-full p-4 bg-gray-200 rounded-lg border-2`}>
//           <div className="w-full h-60 border-2 rounded-lg border-blue-500 relative">
//             {renderUploadButton1(blockButtonsMapping[1][0])}
//           </div>
//           <Modal
//             isOpen={modalIsOpen}
//             onRequestClose={() => setModalIsOpen(false)}
//             className="modal"
//             style={customModalStyles}
//             overlayClassName="overlay"
//           >
//             <Gallery
//               onImageSelect={handleImageSelect1}
//               dimension="36:13"
//               closeModal={() => setModalIsOpen(false)}
//             />
//           </Modal>
//         </div>
//       )}

//       {activeBlock === 1 && (
//         <div className={`w-full p-4 bg-gray-200 rounded-lg flex border-2`}>
//           <div className="w-2/3 h-74 border-2 rounded-lg border-blue-500 relative">
//             {renderUploadButton2(blockButtonsMapping[0][0])}
//           </div>
//           <div className="w-1/3 flex flex-col justify-between ml-2">
//             <div className="h-24 border-2 rounded-sm border-blue-500 mb-1 relative">
//               {renderUploadButton2(blockButtonsMapping[0][1])}
//             </div>
//             <div className="h-24 border-2 rounded-sm border-blue-500 mb-1 relative">
//               {renderUploadButton2(blockButtonsMapping[0][2])}
//             </div>
//             <div className="h-24 border-2 rounded-sm border-blue-500 relative">
//               {renderUploadButton2(blockButtonsMapping[0][3])}
//             </div>
//             <Modal
//               isOpen={modalIsOpen}
//               onRequestClose={() => setModalIsOpen(false)}
//               className="modal"
//               style={customModalStyles}
//               overlayClassName="overlay"
//             >
//               <Gallery
//                 onImageSelect={handleImageSelect2}
//                 dimension={reqDimension}
//                 closeModal={() => setModalIsOpen(false)}
//               />
//             </Modal>
//           </div>
//         </div>
//       )}
//       {activeBlock === 2 && (
//         <div
//           className={`w-full p-4 bg-gray-200 rounded-lg flex gap-3 border-2`}
//         >
//           <div className="w-2/4 h-60 border-2 rounded-sm border-blue-500 relative">
//             {renderUploadButton3(blockButtonsMapping[2][0])}
//           </div>
//           <div className="w-2/4 flex flex-col justify-between gap-3">
//             <div className="flex row gap-3">
//               <div className="w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative">
//                 {renderUploadButton3(blockButtonsMapping[2][1])}
//               </div>
//               <div className="w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative">
//                 {renderUploadButton3(blockButtonsMapping[2][2])}
//               </div>
//             </div>
//             <div className="flex row gap-3">
//               <div className="w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative">
//                 {renderUploadButton3(blockButtonsMapping[2][3])}
//               </div>
//               <div className="w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative">
//                 {renderUploadButton3(blockButtonsMapping[2][4])}
//               </div>
//             </div>
//           </div>
//           <Modal
//             isOpen={modalIsOpen}
//             onRequestClose={() => setModalIsOpen(false)}
//             className="modal"
//             style={customModalStyles}
//             overlayClassName="overlay"
//           >
//             <Gallery
//               onImageSelect={handleImageSelect3}
//               dimension="4:3"
//               closeModal={() => setModalIsOpen(false)}
//             />
//           </Modal>
//         </div>
//       )}
//       {activeBlock === 3 && (
//         <div
//           className={`w-full  p-4 bg-gray-200 rounded-lg flex gap-4 justify-between border-2`}
//         >
//           <div className="w-1/3 h-60 border-2 rounded-sm border-blue-500 mb-1 relative">
//             {renderUploadButton4(blockButtonsMapping[3][0])}
//           </div>
//           <div className="w-1/3 h-60 border-2 rounded-sm border-blue-500 mb-1 relative">
//             {renderUploadButton4(blockButtonsMapping[3][1])}
//           </div>
//           <div className="w-1/3 h-60 border-2 rounded-sm border-blue-500 mb-1 relative">
//             {renderUploadButton4(blockButtonsMapping[3][2])}
//           </div>
//           <Modal
//             isOpen={modalIsOpen}
//             onRequestClose={() => setModalIsOpen(false)}
//             className="modal"
//             style={customModalStyles}
//             overlayClassName="overlay"
//           >
//             <Gallery
//               onImageSelect={handleImageSelect4}
//               dimension="1:1"
//               closeModal={() => setModalIsOpen(false)}
//             />
//           </Modal>
//         </div>
//       )}
//       {activeBlock === 4 && (
//         <div
//           className={`w-full p-4 bg-gray-200 rounded-lg flex gap-4 justify-between border-2`}
//         >
//           <div
//             // onClick={() => handleBlockClick(4)}
//             className={`w-full bg-gray-200 p-4 rounded-lg  
//             `}
//           >
//             <div
//               className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4`}
//             >
//               <div className="border-2 rounded-lg border-blue-500 h-32 relative">
//                 {renderUploadButton5(blockButtonsMapping[4][0])}
//               </div>
//               <div className="border-2 rounded-lg border-blue-500 row-span-1 md:row-span-2 h-50 relative">
//                 {renderUploadButton5(blockButtonsMapping[4][1])}
//               </div>
//               <div className="border-2 rounded-lg border-blue-500 h-32 relative">
//                 {renderUploadButton5(blockButtonsMapping[4][2])}
//               </div>
//               <div className="border-2 rounded-lg border-blue-500 h-32 relative">
//                 {renderUploadButton5(blockButtonsMapping[4][3])}
//               </div>
//               <div className="border-2 rounded-lg border-blue-500 h-32 relative">
//                 {renderUploadButton5(blockButtonsMapping[4][4])}
//               </div>
//             </div>
//           </div>
//           <Modal
//             isOpen={modalIsOpen}
//             onRequestClose={() => setModalIsOpen(false)}
//             className="modal"
//             style={customModalStyles}
//             overlayClassName="overlay"
//           >
//             <Gallery
//               onImageSelect={handleImageSelect5}
//               dimension={blockFiveDimension}
//               closeModal={() => setModalIsOpen(false)}
//             />
//           </Modal>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SelectedTemplate;
