import React from 'react'
import { RenderCommonUpload } from '../renderCommonUpload'

export const CollageBlockFourteen = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
  return (
    <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4`}>
        <div className="w-full flex justify-evenly gap-4 ">
        <div className="w-1/3 border-2 border-blue-500 h-[231px]">
              <RenderCommonUpload buttonIndex={0} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:8.6"}/>
        </div>
        <div className='w-1/3 flex flex-col gap-4 '>
          <div className="border-2 border-blue-500 h-[106px]">
              <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:4.1"}/>
          </div>
          <div className="border-2 border-blue-500 h-[106px]">
              <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:4.1"}/>
          </div>
        </div>
        <div className='w-1/3 flex flex-col gap-4 '>
          <div className="border-2 border-blue-500 h-[106px]">
              <RenderCommonUpload buttonIndex={3} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:4.1"}/>
          </div>
          <div className="border-2 border-blue-500 h-[106px]">
              <RenderCommonUpload buttonIndex={4} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"7.4:4.1"}/>
          </div>
        </div>

        </div>
        
    </div>
  )
}
