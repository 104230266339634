import React from 'react'
import { RenderCommonUpload } from '../renderCommonUpload'

export const CollageBlockSeventeen = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
  return (
    <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4`}>
    <div className="w-full flex justify-evenly gap-4 ">
    <div className='w-1/2 flex flex-col gap-4 '>
      <div className="border-2 border-blue-500 h-[122px]">
          <RenderCommonUpload buttonIndex={0} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
          setBlockImages={setBlockImages} preview={preview} dimension={"11.2:4.7"}/>
      </div>
      <div className="border-2 border-blue-500 h-[122px]">
          <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
          setBlockImages={setBlockImages} preview={preview} dimension={"11.2:4.7"}/>
      </div>
    </div>
    <div className="w-1/2 border-2 border-blue-500 h-[262px]">
          <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
          setBlockImages={setBlockImages} preview={preview} dimension={"11.2:9.7"}/>
    </div>
    </div>
</div>
  )
}
