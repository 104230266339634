import React from 'react'

export const TemplateBlockZero = ({ templateBlockClick, activeBlock ,setImageCountForSelectedTemplate}) => {
  return (
    <div className='flex flex-col w-full text-center'>
      <div
        onClick={() => {templateBlockClick(0);setImageCountForSelectedTemplate(1)}}
        className={`w-full p-4 bg-gray-200 rounded-lg ${activeBlock === 0 ? "border-red-500" : ""
          } border-2 min-h-[196px]`}
      >
        <div className="h-full border-2 rounded-lg border-blue-500"></div>
      </div>
      <p className='font-bold'>Type 1</p>
    </div>
  )
}










