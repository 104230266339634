import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";
import "./style.css"
import useTokenFromURL from "../../../Context/useTokenFromURL";
import Modal from "react-modal";
import useAxios from "../../../Axios/useAxios";
import Preview from "./Preview";
import Skeleton from "./Skeleton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactSwitch from "react-switch";
import { AiOutlineDelete } from 'react-icons/ai';
import NestedSelect from './NestedSelect';
import { BASE_URL } from '../../../config';
const REACT_APP_BASE_URL = BASE_URL;
const ProductSlider = ({
  widgetType,
  unsavedChanges,
  fetchWidgetOrder,
  setUnsavedChanges,
  setHasInteracted,
  hasInteracted,
  refresh,
  widgetId,
}) => {
    const [formValues, setFormValues] = useState({
        widgetNameInput: "",
        widgetTittleInput : "",
        widgetTypeInput: widgetType,
      });
      const [formErrors, setFormErrors] = useState({});
      const [menuOptions, setMenuOptions] = useState([]);
      const [skuError, setSkuError] = useState({}); // Error message object for SKU validation
      const [isButtonDisabled, setButtonDisabled] = useState(false); // State to manage "Add SKU" button's disabled state 
      const [loading,setLoading]=useState(false);
        const [DataSubmitted, setDataSubmitted] = useState(false);
        const [productVisuals, setProductVisuals] = useState(
          {
            sliderHeader: "",
            numProducts: "", 
            showProductName: false,
            showPrice: false,
            showSKU: false,
            rotationSeconds: "",
          }
        );
        const [showPreview, setShowPreview] = useState(false);
        const [productSelection,setProductSelection]=useState("Automatic");
        const [productSliderId,setProductSliderId]=useState(null);
        const [skuList, setSkuList] = useState([{ id: 1, sku: "" }]); // Initialize with one empty SKU
        const [selectedValue,setSelectedValue]=useState(null);
        const [selectedOption, setSelectedOption] = useState({ id: null, label: null });
      const hasInvalidSku = () => {
        return Object.values(skuError).some(error => error === "SKU is invalid");
    };
     // Store both ID and label
      useAxios();
      useTokenFromURL();
      const fetchMenuOptions = async () => {
          try{
            const response = await axios.get(`${REACT_APP_BASE_URL}/menu-201`);
            setMenuOptions(response.data);
          }
          catch(error){
            console.error(error);
          }
    };
    const preventDirectInput = (event) => {
      // Prevent direct typing
      event.preventDefault();
    };
    useEffect(() => {
      fetchMenuOptions();
    }, [refresh])
   // Store both ID and label
      const [productDetails, setProductDetails] = useState({
       
        view_selected: "bestSellingGlobal", // initial select value for product type
        number_of_product: 1, // default value for the number of products
        
        // list of SKUs for custom product selection
    });
     
    useEffect(() => {
      // Update productDetails when selectedOption changes
      setProductDetails(prevDetails => ({
          ...prevDetails,
          menu_filter : selectedOption.label,
          menuId:parseInt(selectedOption.id),
       
      }));
  }, [selectedOption]); // Listen for changes in selectedOption
   
  // Validate the input values
  const validateErrors = () => {
    const errors = {};
  
    // Existing validation for sliderHeader
    if (!formValues.widgetNameInput.trim()) {
      errors.widgetNameInput = 'Widget Name is required.';
    }
  
    // Validate productSelection radio buttons
    if (!productSelection) {
      errors.productSelection = 'Please select a product type.';
    }
  
  // Validate productDetails view selection
    if (productSelection === "Automatic") {

    if (!selectedValue || (Array.isArray(selectedValue) && selectedValue.length === 0)) {
        errors.productDetailsView = 'Please Choose Menus';
    }

    if (selectedValue?.length < 5) {
      errors.productDetailsView = 'Please Select Minimum 5 Menu items.';
    }
    }

    if(productSelection === "Custom") {
      if (skuList.length < 5) {
        errors.skuMin5 = 'Sku must be at least 5'
      }
    }

    // Validate number of products
    if (productSelection === "Automatic" && 
        (!productDetails.number_of_product || productDetails.number_of_product < 1 || productDetails.number_of_product > 20)) {
      errors.numberOfProducts = 'Please select a valid number of products (1-20).';
    }
  
 
    // ... you can continue validating other fields here ...
  
    setFormErrors(errors);
  };
  
  useEffect(() => {
    validateErrors();
    
  }, [productVisuals,productDetails.number_of_product,productSelection,selectedValue, skuList, formValues]);
  
  const handleSelectValueChange = (e) => {
    setProductDetails(prevState => ({
      ...prevState,
      view_selected : e.target.value
  }));
  IsSavedInputs();
  };
  
  const handleNumProductsChange = (e) => {
      setProductDetails(prevState => ({
          ...prevState,
          number_of_product: e.target.value
      }));
      IsSavedInputs();
  };
  const isSkuRequired = () => {
    return productSelection === "Custom" && skuList.every(sku => !sku.sku.trim()) && skuList.length < 5;
  };
  
    const addSku = () => {
        const newId = skuList.length ? skuList[skuList.length - 1].id + 1 : 1;
        setSkuList((prevSkus) => [...prevSkus, { id: newId, sku: "" }]);
        // Immediately set the error for the newly added SKU
    setSkuError((prevErrors) => ({ ...prevErrors, [newId]: "SKU is required" }));
    setButtonDisabled(true);
    };
    const validateSku = async (id, value) => {
      try {
          const response = await axios.get(`${REACT_APP_BASE_URL}/product-master/${value}`);
          const data = response.data;
  
          if (data.status === "success") {
              setSkuError((prevErrors) => {
                  const updatedErrors = { ...prevErrors };
                  delete updatedErrors[id];  // Remove the error entry for the valid SKU
                  return updatedErrors;
              });
              setButtonDisabled(false);
          } else {
              setSkuError((prevErrors) => ({ ...prevErrors, [id]: data.message || "Invalid SKU" }));
              setButtonDisabled(true);
          }
      } catch (error) {
          setSkuError((prevErrors) => ({ ...prevErrors, [id]: "SKU is invalid" }));
          setButtonDisabled(true);
      }
  };
  
    const updateSku = (id, value) => {
      // If the value is empty, set the error message for this SKU
      if (!value.trim()) {
          setSkuError((prevErrors) => ({ ...prevErrors, [id]: "SKU is required" }));
          IsSavedInputs();
        } else {
          validateSku(id, value);
          IsSavedInputs();
        }
        setButtonDisabled(true);
  
      const newSkuList = skuList.map((sku) => (sku.id === id ? { ...sku, sku: value } : sku));
      setSkuList(newSkuList);
      IsSavedInputs();
  };
  
  
    
    const removeSku = (id) => {
      setSkuError({});
      setButtonDisabled(false);
      IsSavedInputs();
      const newSkuList = skuList.filter((sku) => sku.id !== id);
      setSkuList(newSkuList);
      // setButtonDisabled(false);
  };
  
  
      const handleChange = (e) => {
        // setIsFormSubmitted(true);
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        IsSavedInputs();
      };
      const handleVisualChange = (field, value) => {
        const updatedVisuals = { ...productVisuals };
        updatedVisuals[field] = value;
        setProductVisuals(updatedVisuals);
        IsSavedInputs();
    };
    
    
      const IsSavedInputs = () => {
        setHasInteracted(true);
        setUnsavedChanges(true);
    };
    const handleFormSubmit = (e)=>{
      e.preventDefault();

      const payload = {
        widget_name: formValues.widgetNameInput,
        widget_title: formValues.widgetTittleInput,
        slider_header: productVisuals.sliderHeader,
        number_of_products: parseInt(productVisuals.numProducts),
        is_product_name: productVisuals.showProductName ? 1 : 0,
        is_price: productVisuals.showPrice ? 1 : 0,
        is_sku: productVisuals.showSKU ? 1 : 0,
        product_selection: productSelection,
        rotation_seconds: parseInt(productVisuals.rotationSeconds),
        custom_product: skuList.map(({ id, ...rest }) => rest)
    }

    const payload1 = {
      widget_name: formValues.widgetNameInput,
      widget_title: formValues.widgetTittleInput,
      slider_header: productVisuals.sliderHeader,
      number_of_products: parseInt(productVisuals.numProducts),
      is_product_name: productVisuals.showProductName ? 1 : 0,
      is_price: productVisuals.showPrice ? 1 : 0,
      is_sku: productVisuals.showSKU ? 1 : 0,
      rotation_seconds: parseInt(productVisuals.rotationSeconds),
      product_selection: productSelection,
      automatic_product: [{ // Note the array brackets here
          ...productDetails,
          number_of_product: parseInt(productDetails.number_of_product),
          automatic_menu:selectedValue,
          // menu_filter: productDetails.menuFilter || "",
          // menu_id: parseInt(productDetails.menu_id)
      }]
  }

  const FinalPayload = productSelection === "Automatic" ? payload1 : payload;
      try{
          axios.patch(`${REACT_APP_BASE_URL}/product-slider/${widgetId}`,FinalPayload).then((res)=>{

            fetchWidgetOrder();
            fetchProductSliderData();
            if(DataSubmitted) {
              Swal.fire({
                  icon: "success",
                  title: "Data Updated successfully.",
                  showConfirmButton: false,
                  timer: 1500,
              });
          } else {
              Swal.fire({
                  icon: "success",
                  title: "Data Sent successfully.",
                  showConfirmButton: false,
                  timer: 1500,
              });
          }
          setShowPreview(true);
            setUnsavedChanges(false);
          }).catch((err)=>{
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          })
      }
      catch{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }finally {
        setShowPreview(false);
        // setIsFormSubmitted(false); // Set isFormSubmitted back to false when form submission is complete
      }
    }

const fetchProductSliderData = async ()=>{
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/product-slider/${widgetId}`
    );
    setLoading(true);
    setFormFields(response.data);
  } catch (error) {
    setLoading(true);
    console.error(error);
  }
}

const setFormFields = (data) => {
  // For the basic form values
  setFormValues({
    widgetNameInput: data.widget_name,
    widgetTittleInput : data.widget_title,
    widgetTypeInput: data.widget_type,
  });

  // For the product visuals
  setProductVisuals({
    sliderHeader: data.slider_header,
    numProducts: data.number_of_products,
    showProductName: data.is_product_name === 1,
    showPrice: data.is_price === 1,
    showSKU: data.is_sku === 1,
    rotationSeconds: data.rotation_seconds,
  });
  setProductSliderId(data.product_slider_id);
  // Set product selection
  setProductSelection(data.product_selection);

  // For custom products
  if (data.product_selection === "Custom" && data.custom_product.length) {
    // Assuming custom_product is an array of SKUs (without the id field)

    const updatedCustomProduct = data.custom_product.map((sku, index) => ({
      id: index + 1, // setting id to start from 1
      sku: sku.sku, // or just `sku` if the custom_product array is a simple array of SKUs
    }));
    
    setSkuList(updatedCustomProduct);

    setProductDetails({
      view_selected: "bestSellingGlobal",
      number_of_product: null,
 
    });
  }

  // For automatic products (based on your current structure)
  if (data.product_selection === "Automatic" && data.automatic_product?.length) {
    const updatedCustomProduct = data.custom_product.map((sku, index) => ({
      id: index + 1, // setting id to start from 1
      sku: sku.sku, // or just `sku` if the custom_product array is a simple array of SKUs
    }));
    const autoProduct = data?.automatic_product[0]; // Assuming there's only one object in the array
    setProductDetails({
      ...productDetails, 
      view_selected: productDetails.view_selected || "bestSellingGlobal", 
      number_of_product: autoProduct?.number_of_product || productDetails.number_of_product,
    });
    setSelectedValue(autoProduct?.automatic_menu);
    setSkuList(updatedCustomProduct);
  }
  // setSkuList([]);
  setLoading(true);

  const formsubmit = data.product_selection !== "" ? true : false;
  setDataSubmitted(formsubmit);
}

// useEffect(() => {
//   fetchProductSliderData();

// }, [refresh]);

useEffect(() => {
  fetchProductSliderData();
  setLoading(false);
  setSelectedValue(null);
  setProductDetails({
    view_selected: "bestSellingGlobal",
    number_of_product: null,
    
  });
  setSkuList([{
      id:1,
      sku:""
  }])
}, [refresh,productSliderId]);
return <div className="h-full w-full relative">


{!loading ? <Skeleton /> :
  <div className="h-[94%] overflow-auto px-4">
    <h1 className="text-lg font-bold font-poppins text-center text-gray-600 pb-2">
      Product Slider Customization
    </h1>
    {DataSubmitted ? <div>
      <h1 className="text-lg text-center font-poppins text-gray-600">
        Preview
      </h1>
      {DataSubmitted ? <Preview widgetId={widgetId} showPreview={showPreview} /> : false}
      {/* { DataSubmitted ?  <Preview widgetId={widgetId}/>:false} */}

    </div> : false}
    <div className="twenty-second-step">
      <form className="space-y-4 pt-6">
        <div className="flex space-x-4">
          <div className="w-1/2  ">
            <label
              htmlFor="widgetNameInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Name*
            </label>
            <input
              id="widgetNameInput"
              name="widgetNameInput"
              type="text"
              placeholder={formValues.widgetNameInput}
              value={formValues.widgetNameInput}
              onChange={handleChange}
              className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {formErrors.widgetNameInput && <p className="text-red-500 font-poppins text-sm mt-2">{formErrors.widgetNameInput}</p>}
          </div>
          <div className="w-1/2 ">
            <label
              htmlFor="widgetTittleInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Title
            </label>
            <input
              id="widgetTittleInput"
              name="widgetTittleInput"
              type="text"
              placeholder={formValues.widgetTittleInput}
              value={formValues.widgetTittleInput}
              onChange={handleChange}
              className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="widgetTypeInput"
              className="block text-sm font-medium text-gray-700"
            >
              Widget Type
            </label>
            <input
              id="widgetTypeInput"
              name="widgetTypeInput"
              type="text"
              disabled
              placeholder={formValues.widgetTypeInput}
              value={formValues.widgetTypeInput}
              className="mt-1 focus:ring-blue-500 bg-gray-200 opacity-50 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </form>

      <div className="mt-4">
        <Accordion >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>Product Slider Visual</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <div className="flex space-x-4">
          <div className="w-2/3">
          <label
          htmlFor="widgetNameInput"
          className="block text-sm font-medium text-gray-700"
          >
          Slider Header
          </label>
          <input
          id="sliderHeader"
          name="sliderHeader"
          type="text"
          placeholder={productVisuals.sliderHeader}
          value={productVisuals.sliderHeader}
          onChange={(e) => handleVisualChange("sliderHeader", e.target.value)}
          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
          </div>
        </div> */}
            {/* {formErrors.sliderHeader && <p className="text-red-500 font-poppins text-sm mt-2">{formErrors.sliderHeader}</p>} */}
            <div className="flex space-x-4 mt-4">
              <div className="w-2/3">
                <label
                  htmlFor="widgetNameInput"
                  className="block text-sm font-medium text-gray-700"
                >
                  Number of Products at a time (Max: 5)
                </label>
                <input
                  id="rotationSeconds"
                  name="rotationSeconds"
                  type="number"
                  min={4}
                  max={5}
                  onKeyDown={preventDirectInput}
                  value={productVisuals.numProducts}
                  onChange={(e) => handleVisualChange("numProducts", e.target.value)}
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>

            </div>
            <div className="flex space-x-4 mt-3">
              <div className="w-2/3">

                <div className="flex space-x-4 mt-3">
                  <div className="w-full mt-4">
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>Product Detail to be Displayed</Typography>

                    {/* Container for all the switches */}
                    <div className="flex mt-4 justify-between items-center">
                      {/* Product Name Switch */}
                      <div className="flex items-center">
                        <label className="mr-3 text-sm font-poppins">Product Name</label>
                        <ReactSwitch
                          handleDiameter={18}
                          checked={productVisuals.showProductName}
                          onChange={() =>
                            handleVisualChange("showProductName", !productVisuals.showProductName)
                          } />
                      </div>

                      {/* Price Switch */}
                      <div className="flex items-center">
                        <label className="mr-3 text-sm font-poppins">Price</label>
                        <ReactSwitch
                          handleDiameter={18}
                          checked={productVisuals.showPrice}
                          onChange={() =>
                            handleVisualChange("showPrice", !productVisuals.showPrice)
                          }
                        />
                      </div>

                      {/* SKU Switch */}
                      <div className="flex items-center">
                        <label className="mr-3 text-sm font-poppins">SKU</label>
                        <ReactSwitch
                          handleDiameter={18}
                          checked={productVisuals.showSKU}
                          onChange={() =>
                            handleVisualChange("showSKU", !productVisuals.showSKU)
                          }
                        />
                      </div>
                    </div>
                    <div className="flex space-x-4 mt-8">
                      <div className="w-2/3">
                        <label
                          htmlFor="widgetNameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rotation Seconds
                        </label>
                        <input
                          id="rotationSeconds"
                          name="rotationSeconds"
                          type="number"
                          min={1}
                          max={25}
                          onKeyDown={preventDirectInput}
                          value={productVisuals.rotationSeconds}
                          onChange={(e) => handleVisualChange("rotationSeconds", e.target.value)}
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>

    <div className="mt-4">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>Product Details</Typography>


        </AccordionSummary>
        <AccordionDetails>
          <div className="flex justify-start items-center space-x-6">
            <div>
              <label>
                <input
                  type="radio"
                  value="Automatic"
                  checked={productSelection === "Automatic"}
                  onChange={(e) => setProductSelection(e.target.value)}
                />
                <span className="ml-2 font-poppins text-blue-500">Automatic Product Selection</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="Custom"
                  checked={productSelection === "Custom"}
                  onChange={(e) => setProductSelection(e.target.value)}
                />
                <span className="ml-2 font-poppins text-blue-500">Custom Product Selection</span>
              </label>
            </div>
            {formErrors.productSelection && <p className="text-red-500 font-poppins text-sm mt-2">{formErrors.productSelection}</p>}
          </div>
          {productSelection === "Automatic" && (
            <div className="w-2/3">
              <div className="flex flex-col space-y-4">
                {/* Select box */}
                <select
                  value={productDetails.view_selected}
                  onChange={handleSelectValueChange}
                  className="mt-4 font-poppins bg-white rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 text-base"
                >
                  <option value="bestSellingGlobal">Best Selling Products (Global)</option>
                  <option value="topRated">Top-Rated Products</option>
                  <option value="bestSellingLocal">Best Selling Products (Local)</option>
                  <option value="latestArrivals">Latest Arrivals</option>
                </select>

                <div>
                  <Typography> Number of Products </Typography>

                  <input
                    id="rotationSeconds"
                    name="rotationSeconds"
                    type="number"
                    min={1}
                    max={20}
                    onKeyDown={preventDirectInput}
                    value={productDetails.number_of_product}
                    onChange={handleNumProductsChange}
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  {formErrors.numberOfProducts && <p className="text-red-500 font-poppins text-sm mt-2">{formErrors.numberOfProducts}</p>}
                </div>
                {/* Menu filter input */}
                <div>
                  <Typography> Menu Filter </Typography>
                  <div className="relative w-full">
                    <NestedSelect productDetails={productDetails} selectedOption={selectedOption} IsSavedInputs={IsSavedInputs} setSelectedOption={setSelectedOption} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
                    {formErrors.productDetailsView && <p className="text-red-500 font-poppins text-sm mt-2">{formErrors.productDetailsView}</p>}
                  </div>
                </div>
              </div>
            </div>
          )}

          {productSelection === "Custom" && (
            <div className="py-5">
              {/* SKU Input and Delete Icons */}
              {skuList.map((sku) => (
                <div key={sku.id} className="flex items-start space-x-4 mb-3">
                  <label className="text-sm font-medium text-gray-700 self-center">
                    Sku
                  </label>
                  <div className="flex flex-col w-1/3">
                    <input
                      type="text"
                      placeholder="Enter Sku"
                      value={sku.sku}
                      onChange={(e) => updateSku(sku.id, e.target.value)}

                      className="focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
                    />
                    {/* Show error message specific to this SKU */}
                    {skuError[sku.id] && <p className="text-red-500 mt-2">{skuError[sku.id]}</p>}
                  </div>
                  <div className="self-center">
                    <AiOutlineDelete
                      className="text-red-500 cursor-pointer"
                      size={24}
                      onClick={() => removeSku(sku.id)}
                    />
                  </div>
                </div>
              ))}
              {/* {isSkuRequired() ? <p className="text-red-500 mt-2">Must Have Min 5 SKU</p>:false} */}
              <button
                onClick={addSku}
                disabled={isButtonDisabled || hasInvalidSku()}
                className={`bg-blue-500 text-white rounded px-4 py-1 mt-3 ${isButtonDisabled || hasInvalidSku() ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                Add SKU
              </button>
              {formErrors.skuMin5 && <p className="text-red-500 mt-2">{formErrors.skuMin5}</p>}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
    {loading ? <div className="pt-3">

      {Object.keys(formErrors).length !== 0 || Object.keys(skuError).length || isSkuRequired() ?

        <span className="text-red-500 font-poppins text-md ">
          ** Kindly fill the all required fields
        </span> : false


      }
    </div> : false}
  </div>
}



<div className="w-full absolute left-0 bottom-0 px-2 pt-2 bg-white">
  <button
    onClick={handleFormSubmit}
    disabled={Object.keys(formErrors).length !== 0 || Object.keys(skuError).length !== 0 || isSkuRequired()}
    className={
      Object.keys(formErrors).length !== 0 || Object.keys(skuError).length !== 0 || isSkuRequired()
        ? "text-white font-bold py-2 px-4 rounded-md opacity-50 bg-blue-500 cursor-not-allowed"
        : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
    }
  >
    {DataSubmitted ? "Update" : "Save"}
  </button>
</div>
</div>
};

export default ProductSlider;
