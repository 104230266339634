import React from 'react'
import { RenderCommonUpload } from '../renderCommonUpload'

export const CollageBlockTwo = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
  return (
    <div
    className={`w-full p-4 bg-gray-200 rounded-lg flex gap-3 border-2`}
  >
    <div className='w-2/4 h-60 border-2 rounded-sm border-blue-500 relative'>
<RenderCommonUpload buttonIndex={0} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"4:3"}/>
</div>
    <div className='w-2/4 flex flex-col justify-between gap-3'>
      <div className='flex row gap-3'>
        <div className='w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative'>
        <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"4:3"}/>        </div>
        <div className='w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative'>
        <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"4:3"}/>        </div>
      </div>
      <div className='flex row gap-3'>
        <div className='w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative'>
        <RenderCommonUpload buttonIndex={3} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"4:3"}/>        </div>
        <div className='w-2/4 h-28 border-2 rounded-sm border-blue-500 mb-1 relative'>
        <RenderCommonUpload buttonIndex={4} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"4:3"}/>        </div>
      </div>
    </div>
  </div>
  )
}



// import React from 'react'
// import { RenderCommonUpload } from '../renderCommonUpload'

// export const CollageBlockFour = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
//   return (
//     <div className={`w-full md:w-1/2 p-4 bg-gray-200 rounded-lg flex flex-col gap-4 `}>
//         <div className="w-full flex justify-evenly gap-4">
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview} dimension={""}/>
//           </div>
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//         </div>
//         <div className="w-full flex justify-evenly gap-4">
//           <div className="w-2/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={3} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={4} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//         </div>
//         <div className="w-full flex justify-evenly gap-4">
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={5} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//           <div className="w-2/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={6} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//         </div>
//     </div>
    
//   )
// }
