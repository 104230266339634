import React from 'react';

const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center  p-4">
      <img
        src="https://static.vecteezy.com/system/resources/previews/004/112/207/original/login-access-denied-illustration-system-refuses-password-error-and-entry-to-computer-device-showing-user-does-not-have-permission-for-website-or-mobile-development-vector.jpg"
        alt="Access Denied"
        className="w-1/2 h-auto mb-6"
      />
    </div>
  );
};

export default ErrorPage;