import React from 'react'

export const TemplateBlockSeven = ({ templateBlockClick, activeBlock }) => {
  return (
    <div className='flex flex-col w-full text-center'>

      <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4 ${activeBlock === 7 ? "border-red-500 border-2" : ""} h-fit`}
        onClick={() => templateBlockClick(7,5)}
      >
        <div className="w-full flex justify-evenly gap-4">
          <div className="w-1/5 h-32 border-2 border-blue-500"></div>
          <div className="w-1/5 h-32 border-2 border-blue-500"></div>
          <div className="w-1/5 h-32 border-2 border-blue-500"></div>
          <div className="w-1/5 h-32 border-2 border-blue-500"></div>
          <div className="w-1/5 h-32 border-2 border-blue-500"></div>
        </div>
      </div>
      <p className='font-bold'>Type 8</p>
    </div>
  )
}
