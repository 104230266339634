import React from 'react'

export const TemplateBlockThree = ({ templateBlockClick, activeBlock ,setImageCountForSelectedTemplate}) => {
  return (
    <div className='flex flex-col w-full text-center h-38'>
      <div
        className={`w-full p-4 bg-gray-200 rounded-lg flex gap-4 justify-between ${activeBlock === 3 ? "border-red-500" : ""
          } border-2 h-full`}
        onClick={() => {templateBlockClick(3);setImageCountForSelectedTemplate(3)}}
      >
        <div className="w-1/3 h-38 border-2 rounded-sm border-blue-500 mb-1"></div>
        <div className="w-1/3 h-38 border-2 rounded-sm border-blue-500 mb-1"></div>
        <div className="w-1/3 h-38 border-2 rounded-sm border-blue-500 mb-1"></div>
      </div>
      <p className='font-bold'>Type 4</p>
    </div>
  )
}
