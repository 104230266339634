import React from "react";
import { RenderCommonUpload } from "../renderCommonUpload";

export const CollageBlockZero = ({
  IsSavedInputs,
  blockImages,
  setBlockImages,
  preview = false,
}) => {
  return (
    <div className={`w-full p-4 bg-gray-200 rounded-lg border-2`}>
      <div className="w-full h-60 border-2 rounded-lg border-blue-500 relative">
        <RenderCommonUpload
          buttonIndex={0}
          IsSavedInputs={IsSavedInputs}
          blockImages={blockImages}
          setBlockImages={setBlockImages}
          preview={preview}
          dimension={"36:13"}
        />
      </div>
    </div>
  );
};
