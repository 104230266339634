import React from 'react'
import { RenderCommonUpload } from '../renderCommonUpload'

export const CollageBlockOne = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
  return (
    <div className={`w-full p-4 bg-gray-200 rounded-lg flex border-2`}>
    <div className="w-2/3 h-74 border-2 rounded-lg border-blue-500 relative">
    <RenderCommonUpload buttonIndex={0} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"3.7:3"}/>
    </div>
    <div className="w-1/3 flex flex-col justify-between ml-2">
      <div className="h-24 border-2 rounded-sm border-blue-500 mb-1 relative">
      <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"6.4:2.5"}/>      </div>
      <div className="h-24 border-2 rounded-sm border-blue-500 mb-1 relative">
      <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"6.4:2.5"}/>      </div>
      <div className="h-24 border-2 rounded-sm border-blue-500 relative">
      <RenderCommonUpload buttonIndex={3} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"6.4:2.5"}/>      </div>
    </div>
  </div>
  )
}

// import React from 'react'
// import { RenderCommonUpload } from '../renderCommonUpload'

// export const CollageBlockFour = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
//   return (
//     <div className={`w-full md:w-1/2 p-4 bg-gray-200 rounded-lg flex flex-col gap-4 `}>
//         <div className="w-full flex justify-evenly gap-4">
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview} dimension={""}/>
//           </div>
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//         </div>
//         <div className="w-full flex justify-evenly gap-4">
//           <div className="w-2/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={3} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={4} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//         </div>
//         <div className="w-full flex justify-evenly gap-4">
//           <div className="w-1/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={5} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//           <div className="w-2/3 h-36 border-2 border-blue-500">
//               <RenderCommonUpload buttonIndex={6} IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={preview}/>
//           </div>
//         </div>
//     </div>
    
//   )
// }
