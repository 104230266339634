import React from 'react'
import { CollageBlockFour } from './moreCollageTemplate/customizationBlocks/collageBlockFour'
import { CollageBlockFive } from './moreCollageTemplate/customizationBlocks/collageBlockFive'
import { CollageBlockSix } from './moreCollageTemplate/customizationBlocks/collageBlockSix'
import { CollageBlockSeven } from './moreCollageTemplate/customizationBlocks/collageBlockSeven'
import { CollageBlockEight } from './moreCollageTemplate/customizationBlocks/collageBlockEight'
import { CollageBlockNine } from './moreCollageTemplate/customizationBlocks/collageBlockNine'
import { CollageBlockTen } from './moreCollageTemplate/customizationBlocks/collageBlockTen'
import { CollageBlockEleven } from './moreCollageTemplate/customizationBlocks/collageBlockEleven'
import { CollageBlockTwelve } from './moreCollageTemplate/customizationBlocks/collageBlockTwelve'
import { CollageBlockThirteen } from './moreCollageTemplate/customizationBlocks/collageBlockThirteen'
import { CollageBlockFourteen } from './moreCollageTemplate/customizationBlocks/collageBlockFourteen'
import { CollageBlockFifteen } from './moreCollageTemplate/customizationBlocks/collageBlockFifteen'
import { CollageBlockSixteen } from './moreCollageTemplate/customizationBlocks/collageBlockSixteen'
import { CollageBlockSeventeen } from './moreCollageTemplate/customizationBlocks/collageBlockSeventeen'
import { CollageBlockEighteen } from './moreCollageTemplate/customizationBlocks/collageBlockEighteen'
import { CollageBlockNineteen } from './moreCollageTemplate/customizationBlocks/collageBlockNineteen'
import { CollageBlockTwenty } from './moreCollageTemplate/customizationBlocks/collageBlockTwenty'
import { CollageBlockTwentyOne } from './moreCollageTemplate/customizationBlocks/collageBlockTwentyOne'
import { CollageBlockTwentyTwo } from './moreCollageTemplate/customizationBlocks/collageBlockTwentyTwo'
import { CollageBlockTwentyThree } from './moreCollageTemplate/customizationBlocks/collageBlockTwentyThree'
import { CollageBlockTwentyFour } from './moreCollageTemplate/customizationBlocks/collageBlockTwentyFour'
import { CollageBlockThree } from './moreCollageTemplate/customizationBlocks/collageBlockThree'
import { CollageBlockTwo } from './moreCollageTemplate/customizationBlocks/collageBlockTwo'
import { CollageBlockOne } from './moreCollageTemplate/customizationBlocks/collageBlockOne'
import { CollageBlockZero } from './moreCollageTemplate/customizationBlocks/collageBlockZero'

const PreviewCollage = ({
  SelectedActiveBlock,
  blockImages, IsSavedInputs, setBlockImages
}) => {
  const hasImages = () => {
    return [blockImages].some(blockImages => blockImages.some(image => image))
  }


  return (
    <>
      {hasImages() ? (
        <div className='mt-4 '>
          {SelectedActiveBlock !== null ? (
            <h2 className='text-md font-poppins text-center text-gray-600 mb-3'>
              Preview{' '}
            </h2>
          ) : (
            false
          )}
          {SelectedActiveBlock === 0 ? (
            (<CollageBlockZero IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
          ) : null}
          {SelectedActiveBlock === 1 ? (
            (<CollageBlockOne IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
          ) : null}
          {SelectedActiveBlock === 2 ?
            (<CollageBlockTwo IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 3 ?
            (<CollageBlockThree IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 4 ?
            (<CollageBlockFour IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 5 ?
            (<CollageBlockFive IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 6 ?
            (<CollageBlockSix IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 7 ?
            (<CollageBlockSeven IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 8 ?
            (<CollageBlockEight IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 9 ?
            (<CollageBlockNine IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 10 ?
            (<CollageBlockTen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 11 ?
            (<CollageBlockEleven IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 12 ?
            (<CollageBlockTwelve IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 13 ?
            (<CollageBlockThirteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 14 ?
            (<CollageBlockFourteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 15 ?
            (<CollageBlockFifteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 16 ?
            (<CollageBlockSixteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 17 ?
            (<CollageBlockSeventeen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 18 ?
            (<CollageBlockEighteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 19 ?
            (<CollageBlockNineteen IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 20 ?
            (<CollageBlockTwenty IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 21 ?
            (<CollageBlockTwentyOne IsSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 22 ?
            (<CollageBlockTwentyTwo sSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 23 ?
            (<CollageBlockTwentyThree sSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
          {SelectedActiveBlock === 24 ?
            (<CollageBlockTwentyFour sSavedInputs={IsSavedInputs} blockImages={blockImages} setBlockImages={setBlockImages} preview={true} />)
            : null}
        </div>
      ) : false}
    </>
  )
}

export default PreviewCollage