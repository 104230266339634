import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SketchPicker } from "react-color";
import CustomDropdown from "./CustomDropdown";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import Modal from "react-modal";
import Swal from "sweetalert2";
import useTokenFromURL from "../../Context/useTokenFromURL";
import FontFaceObserver from "fontfaceobserver";
import { debounce } from "lodash"; // <-- lodash debounce function
import { BASE_URL } from '../../config';
import { FiUpload } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import Gallery from "../Gallery";
import { IoMdCloseCircle } from "react-icons/io";
const REACT_APP_BASE_URL =BASE_URL;

const WebsiteStandard = ({activeAccordion,onToggle,IsSavedInputs}) => {
    useTokenFromURL();
    useAxios();
    const [websiteStandard, setWebsiteStandard] = useState({
        primary_colour: "#80274f",
        secondary_colour: "#DFD0CB",
        alternative_colour: "#2d3748",
        primary_font: "",
        secondary_font: "",
        alternative_font: "",
        fav_icon : ""
      });
      const debouncedLoadFont = debounce(loadFont, 500); // <-- debouncing loadFont
      const [importantFonts, setImportantFonts] = useState([]);
      const [fonts, setFonts] = useState([]);
      const [loading, setLoading] = useState(true);
      const [dataSubmitted,setDataSubmitted]=useState(false);
      const [favIcon, setFavIcon] = useState('');
      const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    //   const [errors, setErrors] = useState({
    //     primary_font: "",
    //     secondary_font: "",
    //     alternative_font: "", 
    //     image: "",
    //   });
    const [loading1,setLoading1]=useState(true);
      const [loadedFonts, setLoadedFonts] = useState([]);
      const [isModalOpen, setIsModalOpen] = useState(false);
    const [colorType, setColorType] = useState("primary_colour");
    const [selectedColor, setSelectedColor] = useState(
        websiteStandard.primary_colour
      );
      const [fontloading, setFontLoading] = useState(true);
      const [isAccordionLoading, setAccordionLoading] = useState(true);
      const [errors, setErrors] = useState({});
      const FontDropdownSkeleton = () => (
        <div className="mt-2 w-full p-2 border rounded">
          <div className="h-6 bg-gray-200 skeleton-shimmer"></div>
        </div>
      );
      const handleColorChange = (color) => {
        setSelectedColor(color.hex);
      };
      const handleSaveColor = () => {
        setWebsiteStandard((prevStandard) => ({
          ...prevStandard,
          [colorType]: selectedColor,
        }));
        setIsModalOpen(false);
      };
    
      const handleOpenModal = (type) => {
        setColorType(type);
        setSelectedColor(websiteStandard[type]);
        setIsModalOpen(true);
        IsSavedInputs();
      };

      async function fetchCustomFonts() {
        try {
          await axios.get(`${REACT_APP_BASE_URL}/custom-font`).then((res) => {
            setImportantFonts(res.data);
            setFontLoading(false);
          });
        } catch (error) {
          console.error("Error fetching fonts:", error);
          return []; // Return an empty array in case of error.
        }
      }
      function loadFont(fontName) {
        const link = document.createElement("link");
        link.href = `https://fonts.googleapis.com/css?family=${fontName.replace(
          / /g,
          "+"
        )}:wght@400;700&display=swap`;
        link.rel = "stylesheet";
        document.head.appendChild(link);
    
        const fontObserver = new FontFaceObserver(fontName);
        fontObserver.load().then(() => {
          const styleSheet = document.createElement("style");
          styleSheet.type = "text/css";
          styleSheet.innerText = `.react-select__option[data-value="${fontName}"], .react-select__single-value { font-family: ${fontName} !important; }`;
          document.head.appendChild(styleSheet);
    
          // Add font to the loadedFonts state
          setLoadedFonts((prevFonts) => [...prevFonts, fontName]);
        });
      }

   
      useEffect(() => {
        if (websiteStandard.primary_font) {
          debouncedLoadFont(websiteStandard.primary_font);
        }
        if (websiteStandard.secondary_font) {
          debouncedLoadFont(websiteStandard.secondary_font);
        }
        if (websiteStandard.alternative_font) {
          debouncedLoadFont(websiteStandard.alternative_font);
        }
      }, [websiteStandard]);

       // Modify the loadFonts function inside the useEffect like this:
       const GOOGLE_FONTS_API =
       "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC08tWS7vjQ4Vn0hxIgdzT6duD7KBOeN8A";
       async function fetchFonts() {
        const response = await axios.get(GOOGLE_FONTS_API);
        return response.data.items;
      }
  useEffect(() => {
    // async function loadFonts() {
    //   const fetchedFonts = await fetchFonts();
    //   let fontsToDisplay = fetchedFonts.filter((font) =>
    //     importantFonts.includes(font.family)
    //   ); // Filter based on your IMPORTANT_FONTS list
      
    //   let tempFont = [
    //     {
    //       "family": "AmsiProCond-Black"
    //     },
    //     {
    //       "family": "halimun"
    //     },
    //     {
    //       "family": "bellisa-script"
    //     },
    //     {
    //       "family": "Gloriant-RxKW"
    //     }
    //   ];

    //   fontsToDisplay = [...fontsToDisplay, ...tempFont]

    //   console.log('fonts to display', fontsToDisplay);
    //   // debugger
    //   fontsToDisplay.forEach((font) => {
    //     if(font.family === "AmsiProCond-Black" || font.family === "halimun" || font.family === "bellisa-script" || font.family === "Gloriant-RxKW") {
    //       return font
    //     }
    //     return loadFont(font.family)
    //   });

    //   setFonts(fontsToDisplay);
    //   setLoading(false);
    //   setAccordionLoading(false);
    // }
    // loadFonts();
    // if (fontloading === true) {
    //   fetchCustomFonts();
    // }
    fetchCustomFonts();
    setFonts(importantFonts);
    setLoading(false);
    setAccordionLoading(false);
  }, [fontloading]);

  async function fetchWebsiteStandardColors() {
    try {
        const response = await axios.get(`${REACT_APP_BASE_URL}/website-standard-colors`);
        setLoading1(false);
        if (response.data && response.data.length > 0) {
            const fetchedData = response.data[0];
            const submitValidate = fetchedData.primary_font !== "" ? true :false
            setDataSubmitted(submitValidate);
            // Structuring data as per payload format
            const structuredData = {
                primary_colour: fetchedData.primary_colour,
                secondary_colour: fetchedData.secondary_colour,
                alternative_colour: fetchedData.alternative_colour,
                primary_font: fetchedData.primary_font,
                secondary_font: fetchedData.secondary_font,
                alternative_font: fetchedData.alternative_font,
                fav_icon: fetchedData.fav_icon,
            };
            setWebsiteStandard(structuredData);
        }
    } catch (error) {
      setLoading1(false);
        console.error("Error fetching website standard colors:", error);
    }
}
useEffect(() => {
    fetchWebsiteStandardColors();
}, [dataSubmitted]);
  const handleSubmit = async () => {
    try {
        const payload = {
        
            primary_colour: websiteStandard.primary_colour,
            secondary_colour: websiteStandard.secondary_colour,
            alternative_colour: websiteStandard.alternative_colour,
            primary_font: websiteStandard.primary_font,
            secondary_font: websiteStandard.secondary_font,
            alternative_font: websiteStandard.alternative_font,
            fav_icon: websiteStandard.fav_icon
        };

        const response = await axios.patch(`${REACT_APP_BASE_URL}/website-standard-colors`, payload);

        if (response.status === 200) {
            if(dataSubmitted){
                Swal.fire({
                  title: 'Success!',
                  text: 'Datas Updated successfully.',
                  icon: 'success',
                  timer: 3000,
                  showConfirmButton: false
                });
              }
              else{
                Swal.fire({
                  title: 'Success!',
                  text: 'Datas saved successfully.',
                  icon: 'success',
                  timer: 3000,
                  showConfirmButton: false
                });
              }
              fetchWebsiteStandardColors();
            // Here you can also set a success message to the user or do other stuff you need after a successful request
        } else {
            console.log("Error saving the data", response.data);
        }
    } catch (error) {
        console.error("There was an error while saving the data", error);
        Swal.fire({
            title: 'Error!',
            text: 'An error occurred. Please try again.',
            icon: 'error'
        });
    }
};

const validateFields = () => {
    const newErrors = {};

    if (!websiteStandard.primary_font) {
        newErrors.primary_font = "Primary font is required.";
    }

    if (!websiteStandard.secondary_font) {
        newErrors.secondary_font = "Secondary font is required.";
    }

    if (!websiteStandard.alternative_font) {
        newErrors.alternative_font = "Alternative font is required.";
    }

    setErrors(newErrors);
};

useEffect(() => {
    validateFields();
}, [websiteStandard]);


const ShimmerSkeleton = () => {
  return (
    <>
    <div className="animate-shimmer bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 w-full h-full rounded">

    </div>
    <div className="animate-shimmer bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 w-full h-full rounded">

    </div>
    </>
  );
};


const handleAccordionChange = () => {
  if (activeAccordion === "WebsiteStandardAccordian") {
    onToggle && onToggle(null); // close the current accordion if it's 'AuthAccordian'.
  } else {
    onToggle && onToggle("WebsiteStandardAccordian"); // open the 'AuthAccordian' if another accordion is currently active.
  }
};

const handleFavIconUpload = () => {
  setIsImageModalOpen(true)
};

const handleImageDelete = () => {
  setWebsiteStandard(prevState => ({
    ...prevState,
    fav_icon: ""
  }));
};

const handleImageSelect = (selectedImage) => {
  setWebsiteStandard(prevState => ({
    ...prevState,
    fav_icon: selectedImage
  }));
  setIsImageModalOpen(false);
};

// const handleFontChange = (font) => {
//     setWebsiteStandard((prev) => ({
//       ...prev,
//       primary_font: font,
//     }))
//     IsSavedInputs();
// }
  return (
    <div className="px-2">
    
        <Accordion   expanded={activeAccordion === "WebsiteStandardAccordian"}
          onChange={handleAccordionChange} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{fontFamily:"poppins"}}>Website Standards</Typography>
          </AccordionSummary>
          {loading ? <ShimmerSkeleton/> :    <AccordionDetails>
            <>
              {/* ------------- */}
              <div className="my-2 bg-gray-100 p-4 rounded-md">
                <div className="flex items-center justify-center space-x-4">
                  <h1 className="text-gray-800 text-lg font-poppins">Upload Favicon : </h1>
                  <div
                    className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer w-16 h-16"
                    onClick={handleFavIconUpload}
                  >
                    {websiteStandard.fav_icon ? (
                      <img src={websiteStandard.fav_icon} alt="data" className="w-full h-full object-cover rounded-md" />
                    ) : (
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                        <FiUpload size="1.5em" />
                      </button>
                    )}
                  </div>
                  {websiteStandard.fav_icon && (
                    <div className="flex row">
                      <div className="text-blue-500 cursor-pointer ml-4" onClick={handleFavIconUpload}>
                        <FiUpload size="1.5em" />
                      </div>
                      <div className="text-red-500 cursor-pointer ml-4" onClick={handleImageDelete}>
                        <AiFillDelete size="1.5em" />
                      </div>
                    </div>
                  )}
                  <p className="text-red-500 font-poppins text-sm mt-2">{/* Display logo error message */}</p>
                </div>
              </div>
              {/* ------------- */}
              <div className="flex space-x-4 w-full">
                {[
                  "primary_colour",
                  "secondary_colour",
                  "alternative_colour",
                ].map((type) => (
                  <div
                    className="flex flex-col justify-between items-start w-1/3"
                    key={type}
                  >
                    <label
                      htmlFor={`colorPicker-${type}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {type.replace("_", " ").charAt(0).toUpperCase() +
                        type.replace("_", " ").slice(1)}
                    </label>
                    <div className="flex justify-start items-start space-x-4 w-full">
                      <div
                        id={`colorPicker-${type}`}
                        className="mt-1 w-full border rounded p-2 cursor-pointer flex items-center space-x-2"
                        onClick={() => handleOpenModal(type)}
                      >
                        <div
                          className="w-10 h-10 border rounded"
                          style={{ backgroundColor: websiteStandard[type] }}
                        ></div>
                        <code className="bg-gray-100 p-1 rounded">
                          {websiteStandard[type]}
                        </code>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                {isAccordionLoading ? (
                  <FontDropdownSkeleton />
                ) : (
                  <div className="flex w-full space-x-4 mt-4">
                    {["primary_font", "secondary_font", "alternative_font"].map(
                      (type) => (
                        <div
                          className="flex flex-col justify-between items-start w-1/3"
                          key={type}
                        >
                          <label
                            htmlFor={`fontPicker-${type}`}
                            className="block text-sm font-medium text-gray-700"
                          >
                            {type.replace("_", " ").charAt(0).toUpperCase() +
                              type.replace("_", " ").slice(1)}
                          </label>
                          <div className="mt-2 ">
                            {errors && (
                              <p
                                className="text-red-500 text-sm font-poppins"
                                style={{ zIndex: 20 }}
                              >
                                {errors[type]}
                              </p>
                            )}
                          </div>
                          {loading ? (
                            <FontDropdownSkeleton />
                          ) : (
                            <div className="relative w-full z-50 ">
                              {/* <!-- Overlay content here --> */}
                              <CustomDropdown
                                value={websiteStandard[type]}
                                onChange={(fontName) =>{

                                  setWebsiteStandard((prev) => ({
                                    ...prev,
                                    [type]: fontName,
                                  }))
                                  IsSavedInputs();
                                }}
                                fonts={fonts}
                                errors={errors[type]}
                              />
                              
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          className="flex items-center justify-center h-screen outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-60 z-50"
        >
          <div className="bg-white rounded-lg p-6 shadow-xl w-96">
            <div className="flex justify-end">
              <IoMdCloseCircle onClick={() => setIsModalOpen(false)} className="cursor-pointer" size={25}/>
            </div>
            <h2 className="text-center font-poppins text-gray-600 text-lg mb-4">
              Select Color
            </h2>
            <div className="flex justify-center">
              <SketchPicker
                color={selectedColor}
                onChangeComplete={handleColorChange}
              />
            </div>
            <button
              onClick={handleSaveColor}
              className="bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-600 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto"
            >
              Save
            </button>
          </div>
        </Modal>
        <Modal isOpen={isImageModalOpen} onRequestClose={() => setIsImageModalOpen(false)}
                style={{
                  overlay: {
                    zIndex: 1000, // Ensure this value is higher than the z-index of SunEditor
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  },
                }}>
                <Gallery
                  dimension="1:1"
                  onImageSelect={handleImageSelect}
                  closeModal={() => setIsImageModalOpen(false)}
                />
              </Modal>
        <button
  onClick={handleSubmit}
  className={`bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${Object.keys(errors).length > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
  disabled={Object.keys(errors).length > 0}
>


  
  {Object.keys(errors).length > 0 ? "Fill the required fields" : (dataSubmitted ? "Update" : "Save")}
</button>
            </>
          </AccordionDetails>}
       
        </Accordion>
    </div>
  )
}

export default WebsiteStandard

