import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { DOMAIN_URL } from '../../config';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { FaGlobeAmericas, FaTag,FaCircle, FaClock, FaPercent, FaCheckCircle, FaShoppingCart,FaCheck } from 'react-icons/fa';

import { useToken } from '../../Context/TokenContext';
import { FaTimes,FaTags,FaLock,FaCalculator,FaPercentage,FaRegCreditCard } from 'react-icons/fa'; // Import FaTimes for the close icon
const SSLComponent = ({ onBack, domainName }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [sslOptions, setSslOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPurchaseSuccessful, setIsPurchaseSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { jboId,token } = useToken();
  useEffect(() => {
    fetchSslRates();
  }, [domainName]);

  const fetchSslRates = async (sslType = '') => {
    setIsLoading(true);
    try {
      const url = `${BASE_URL}/domain/domainssl_rate?domain_name=${domainName}${sslType ? `&ssl_type=${sslType}` : ''}`;
      const response = await axios.get(url);
      if (sslType) {
        setSelectedOption({
          domain: response.data.domain,
          price: response.data.price,
          sslData: response.data.sslData[0], // Assuming the response will only contain one SSL data object when ssl_type is provided
          total: response.data.total,
          CGST: response.data.CGST,
          SGST: response.data.SGST,
          CGST_tax: response.data.CGST_tax,
          SGST_tax: response.data.SGST_tax,
        });
      } else {
        setSslOptions(response.data.sslData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching SSL data:', error);
      setIsLoading(false);
    }
  };
  

  const handleSelection = (option) => {
    fetchSslRates(option.ssl_type);
  };

  const handlePurchase = async () => {
    // Payload data
    const innerPayload = {
      // jbo_id: jboId.toString(), // Convert to string
      amount: selectedOption.total.toString(), // Convert to string and ensure it matches the desired format
    }
  
    // Stringify the inner payload
    const innerPayloadString = JSON.stringify(innerPayload)
    
    // Constructing the outer data object with innerPayloadString
    const data = { data: innerPayloadString,token:token }
    
    try {
      // const response = await axios.post('https://j3sitebuilder.ta4.in/api/jbo_subscription/domainPurchase', data)
      const response = await axios.post(`${DOMAIN_URL}/jbo_subscription/domainPurchase`, data)
      // Assuming response.data.formbody contains the HTML content you want to display in the modal
      setModalContent(response.data.formbody)
      sessionStorage.setItem('domain_name',domainName);
      setIsModalVisible(true) // Show the modal
    } catch (error) {
      console.error('Error making API call:', error)
    }
  }
  
  
  

  return (
    <div className="p-8 max-w-4xl mx-auto bg-white rounded-xl shadow-xl transform transition duration-500 font-poppins">
      <div className="mb-8">
        <button onClick={onBack} className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 transition-colors duration-200 text-white px-4 py-2 rounded-full shadow-md text-sm focus:outline-none">
          <IoIosArrowRoundBack className="text-lg" /> Back
        </button>
        <h3 className="text-xl font-semibold text-gray-800 mt-4">SSL Options for <span className="text-blue-600">{domainName}</span> by JPencil</h3>
      </div>

      <p className="text-gray-700 mb-6 text-justify">
        In today digital marketplace, protecting your online store is essential. JPencil, understanding the critical need for security, offers tailored SSL solutions to protect your eCommerce platform and build customer trust. Explore the SSL options available to enhance your site's security:
      </p>

      {isLoading ? (
        <div className="grid md:grid-cols-2 gap-4 animate-pulse">
          {[1, 2].map((n) => (
            <div key={n} className="bg-gray-50 rounded-lg p-4">
              <div className="h-6 bg-gray-200 rounded mb-4"></div>
              <div className="h-4 bg-gray-200 rounded mb-2"></div>
              <div className="h-4 bg-gray-200 rounded"></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid md:grid-cols-2 gap-4">
        {sslOptions.map((option) => (
          <div
            key={option.ssl_master_id}
            className={`bg-white rounded-lg p-4 shadow transition duration-300 hover:shadow-md cursor-pointer ${selectedOption?.sslData?.ssl_master_id === option.ssl_master_id ? 'border-2 border-blue-600' : ''}`} // Conditional class for selected option
            onClick={() => handleSelection(option)} // Set selected option on click
          >
            <div className="flex justify-between items-center mb-3">
              <div className="text-lg font-medium text-gray-800">
                {option.ssl_type}
              </div>
              <div className={`text-sm font-semibold ${selectedOption?.sslData?.ssl_master_id === option.ssl_master_id ? 'text-blue-600 flex items-center' : 'text-gray-500'}`}>
  {selectedOption?.sslData?.ssl_master_id === option.ssl_master_id ? <FaCheck style={{ color: 'green' }}/> :   <FaCircle style={{ color: 'blue' }}/>}
</div>
            </div>
            <p className="text-gray-600 text-justify">
              {option.ssl_master_id === 1 ? 'The Standard SSL is perfect for small to medium-sized eCommerce businesses looking to secure their online transactions and customer information.' : ''}
              {option.ssl_master_id === 2 ? 'Our Advanced SSL provides the highest level of security for large eCommerce platforms, ensuring the utmost protection for extensive online transactions and sensitive data.' : ''}
            </p>
            <div className="mt-4 text-blue-600 font-semibold text-lg">
               {option.ssl_price === 0 ? 'Free' : `₹ ${option.ssl_price}`}

            </div>
          </div>
        ))}
      </div>
      

      
      )}
{selectedOption && (
  <div className="mt-8 p-6 bg-white rounded-xl shadow-sm font-poppins">
    <h4 className="flex items-center text-xl font-bold text-blue-900 mb-8">
      <FaTags className="mr-3 text-blue-700" />Price BreakUp
    </h4>
    <div className="overflow-x-auto">
      <table className="w-full text-left text-md text-gray-800">
        <thead className="text-md text-gray-800 uppercase bg-gray-100">
          <tr>
            <th scope="col" className="px-6 py-4">
              Description
            </th>
            <th scope="col" className="px-6 py-4">
              Duration
            </th>
            <th scope="col" className="px-6 py-4 text-right">
              Amount
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300">
          <tr>
            <td className="px-6 py-4 font-semibold text-blue-900">
              <FaGlobeAmericas className="inline mr-2 text-blue-700" />
              Domain: {selectedOption.domain}
            </td>
            <td className="px-6 py-4">
              <FaClock className="inline mr-2 text-gray-500" />
              1 Year
            </td>
            <td className="px-6 py-4 text-right font-semibold">
              ₹ {new Intl.NumberFormat('en-IN').format(selectedOption.price)}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 font-medium">
              <FaLock className="inline mr-2 text-blue-500" />
              SSL ({selectedOption.sslData.ssl_type})
            </td>
            <td className="px-6 py-4">
              <FaClock className="inline mr-2 text-gray-500" />
              1 Year
            </td>
            <td className="px-6 py-4 text-right font-semibold">
              ₹ {new Intl.NumberFormat('en-IN').format(selectedOption.sslData.ssl_price)}
            </td>
          </tr>
          <tr className="bg-gray-100">
            <td className="px-6 py-4 font-bold" colSpan="2">
              <FaCalculator className="inline mr-2 text-blue-500" />
              Subtotal
            </td>
            <td className="px-6 py-4 text-right font-bold">
              ₹ {new Intl.NumberFormat('en-IN').format(selectedOption.price + selectedOption.sslData.ssl_price)}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 font-medium" colSpan="2">
              <FaPercentage className="inline mr-2 text-blue-500" />
              CGST ({selectedOption.CGST})
            </td>
            <td className="px-6 py-4 text-right font-semibold">
              ₹ {new Intl.NumberFormat('en-IN').format(selectedOption.CGST_tax)}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 font-medium" colSpan="2">
              <FaPercentage className="inline mr-2 text-blue-500" />
              SGST ({selectedOption.SGST})
            </td>
            <td className="px-6 py-4 text-right font-semibold">
              ₹ {new Intl.NumberFormat('en-IN').format(selectedOption.SGST_tax)}
            </td>
          </tr>
          <tr className="bg-gray-100">
            <td className="px-6 py-4 font-bold text-blue-900" colSpan="2">
              <FaRegCreditCard className="inline mr-2 text-blue-700" />
              Total Amount
            </td>
            <td className="px-6 py-4 text-right font-bold text-blue-900">
              ₹ {new Intl.NumberFormat('en-IN').format(selectedOption.total)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="flex justify-end mt-8">
      <button onClick={handlePurchase} className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline">
        Purchase
      </button>
    </div>
  </div>
)}









       {isModalVisible && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-4xl mx-auto">
      <div className="rounded-t-lg border-t-4 border-blue-600">
        <button onClick={() => setIsModalVisible(false)} className="float-right text-red-600 hover:text-red-700 p-1 rounded-full focus:outline-none">
          <FaTimes className="w-5 h-5" /> {/* Use FaTimes for the close icon */}
        </button>
       
      </div>
      <div className="modal-content" dangerouslySetInnerHTML={{ __html: modalContent }} />
      
    </div>
  </div>
)}   
    </div>
  );
};

export default SSLComponent;
    