
import Modal from "react-modal";
import MoreCollageTemplates from "./MoreCollageTemplates";
import { TemplateBlockThree } from "./moreCollageTemplate/templateBlock/templateBlockThree";
import { TemplateBlockTwo } from "./moreCollageTemplate/templateBlock/templateBlockTwo";
import { TemplateBlockOne } from "./moreCollageTemplate/templateBlock/templateBlockOne";
import { TemplateBlockZero } from "./moreCollageTemplate/templateBlock/templateBlockZero";

const CollageTemplates = ({
  activeBlock,
  setTemplatesModelOpen,
  templatesModelOpen,
  handleBlockClickForCommonTemplate,
  setImageCountForSelectedTemplate
}) => {
  const collageModalStyle = {
    content: {
      width: "70%", // Adjust the width as per your requirement
      margin: "auto", // Center the modal horizontally
    },
  };


  return (
    <div >
      <h1 className="text-lg font-poppins text-center text-gray-600 " >
        Select Collage Structure
      </h1>
      <div className="mt-4 block lg:flex gap-4 ">
        <TemplateBlockZero activeBlock={activeBlock} templateBlockClick={handleBlockClickForCommonTemplate} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate} />
        <TemplateBlockOne activeBlock={activeBlock} templateBlockClick={handleBlockClickForCommonTemplate} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}/>
      </div>
      <div className="mt-6 flex gap-4">
        <TemplateBlockTwo activeBlock={activeBlock} templateBlockClick={handleBlockClickForCommonTemplate} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}/>
        <TemplateBlockThree activeBlock={activeBlock} templateBlockClick={handleBlockClickForCommonTemplate} setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}/>
      </div>
      <Modal
        isOpen={templatesModelOpen}
        onRequestClose={() => setTemplatesModelOpen(false)}
        style={collageModalStyle}
        overlayClassName="overlay"
      >
        <MoreCollageTemplates
          handleBlockClick={handleBlockClickForCommonTemplate}
          activeBlock={activeBlock}
          setTemplatesModelOpen={setTemplatesModelOpen}
          setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}
        />
      </Modal>
    </div>
  );
};

export default CollageTemplates;
