import  React from 'react';
/**
 * MultiSelectWithChips Component
 *
 * This component is a visual dropdown-style selector that also displays chosen selections as chips.
 * As users select items from the dropdown, those items appear as "chips" above the dropdown.
 * Users can also remove chips to de-select items.
 *
 * Props:
 * - isOpen: Boolean determining whether the dropdown is open
 * - setIsOpen: Setter function for the isOpen state
 * - toggleDropdown: Function to toggle the dropdown visibility
 * - footerMenus: Array of menus that can be selected
 * - selectedMenus: Array of already selected menu items
 * - removeChip: Function to remove a chip, which de-selects a menu item
 * - toggleMenuSelection: Function to handle selecting a menu from the dropdown
 * - footerIndex: An identifier for which footer's menus are currently being modified
 */

const MultiSelectWithChips = ({
  isOpen,
  setIsOpen,
  toggleDropdown,
  footerMenus,
  selectedMenus,
  removeChip,
  toggleMenuSelection,
  footerIndex, // New prop to determine which footer's menus to modify
}) => {
  return (
    <div className="relative inline-block w-full">
      <div className="mb-2 mt-3">
        <label className="font-medium font-poppins  text-gray-600 block">
          {" "}
          Menu Lists{" "}
        </label>
        {/* {selectedMenus.length < 4 && (
          <span className="flex font-poppins text-red-700 p-1 text-sm">
            ** Max 4 to be added
          </span>
        )} */}
        {selectedMenus.map((menu, index) => (
          <>
            <span
              key={index}
              className="inline-flex items-center bg-blue-100 rounded px-2 py-1 mr-1 mb-1 mt-1"
            >
              {menu}
              <button
                onClick={() => removeChip(menu, footerIndex)}
                className="ml-1 text-sm"
              >
                {" "}
                {/* Updated here */}✕
              </button>
            </span>
          </>
        ))}
      </div>

      <div className="mt-1 mb-2 block w-full relative">
        <input
          onFocus={toggleDropdown}
          onBlur={() => setTimeout(toggleDropdown, 200)}
          className="border border-gray-300 focus:ring-blue-500 focus:border-blue-700 block w-full sm:text-sm rounded-md p-2"
          placeholder="Select Footer Menu..."
          readOnly
        />

        {isOpen && (
          <div className="absolute top-full mt-2 w-full border rounded-md shadow-lg bg-white z-10">
            {footerMenus.length === 0 ? (
              <div className="cursor-pointer px-4 py-2 font-poppins">
                No records found
              </div>
            ) : (
              footerMenus.map((menu, index) => (
                <div
                  key={index}
                  className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${
                    selectedMenus.includes(menu) ? "bg-gray-200" : ""
                  }`}
                  onClick={() => toggleMenuSelection(menu, footerIndex)}
                >
                  {menu}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectWithChips;
