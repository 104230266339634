import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline"; // import the correct icon
import ReactSwitch from "react-switch";
import { MdDelete, MdRemoveCircleOutline } from "react-icons/md"; // imported remove icon
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import Swal from "sweetalert2";
import { isValidNumber } from 'libphonenumber-js'
import { BASE_URL } from '../../config';
import { useToken } from '../../Context/TokenContext';
// Base URL fetched from Global config
const REACT_APP_BASE_URL =BASE_URL;

// ContactUs  component - It is used to optimize contact us Info Datas
const ContactUs = ({ onToggle, activeAccordion, IsSavedInputs }) => {
  // Initialize hooks for managing token and API calls
  useTokenFromURL();
  useAxios();

  // State for contact us details
  const [contactUs, setContactUs] = useState({
    contact_us_page_id: null,
    description: "",
  });
  // Ref to keep track of previous description for comparison
  const prevDescriptionRef = useRef(contactUs.description);

  // State for contact us store information
  const [contactUsInfo, setContactUsInfo] = useState([
    {
      contact_us_store_id: null,
      primaryToggle: false,
      customStoreToggle: false,
      customDescription: "",
      storeAddress: "",
      storePhoneNumbers: [""], // Changed storePhoneNo to an array
      storeEmail: "",
      storeName: "",
      storeLocation: "",
    },
  ]);

  // Ref to keep track of previous customDescription for comparison
  const prevDescriptionRef1 = useRef(contactUsInfo.customDescription);

  // Default placeholder description
  const [defaultDescription, setDefaultDescription] = useState("<p><br></p>");

  // Errors state for form validation
  const [errors, setErrors] = useState({});
  const {jboId} = useToken();

  // State to check if the data has been submitted
  const [dataSubmitted, setDataSubmitted] = useState(false);

  // Handler for accordion change
  const handleAccordionChange = () => {
    if (activeAccordion === "ContactUsPageAccordian") {
      onToggle && onToggle(null); // close the current accordion
    } else {
      onToggle && onToggle("ContactUsPageAccordian"); // open the clicked accordion
    }
  };
  // Handler for toggling primary store status
  const handlePrimaryToggleChange = (index) => {
    const updatedContactUsInfo = contactUsInfo.map((store, i) => {
      if (i === index) {
        // Toggle the primary state for the clicked accordion
        return { ...store, primaryToggle: !store.primaryToggle };
      } else {
        // Turn off the primary state for other accordions
        return { ...store, primaryToggle: false };
      }
    });

    setContactUsInfo(updatedContactUsInfo);
    IsSavedInputs();
  };

  // Handlers for various store field changes
  const handleStoreFieldChange = (index, field, value) => {
    IsSavedInputs();
    const updatedContactUsInfo = [...contactUsInfo];
    updatedContactUsInfo[index][field] = value;
    setContactUsInfo(updatedContactUsInfo);
  };

  /**
   * Updates the phone number of a particular store at a specific index.
   *
   * @param {number} storeIndex - The index of the store whose phone number needs to be updated.
   * @param {number} phoneIndex - The index of the phone number in the store's phone numbers array.
   * @param {string} phoneNumber - The new phone number to set.
   */
  const handleStorePhoneNumberChange = (
    storeIndex,
    phoneIndex,
    phoneNumber
  ) => {
    const updatedContactUsInfo = [...contactUsInfo];
    updatedContactUsInfo[storeIndex].storePhoneNumbers[phoneIndex] =
      phoneNumber;
    setContactUsInfo(updatedContactUsInfo);
    IsSavedInputs();
  };

  /**
   * Adds a new phone number field for a particular store.
   *
   * @param {number} index - The index of the store where a new phone number field should be added.
   */
  const handleAddPhoneNumber = (index) => {
    const updatedContactUsInfo = [...contactUsInfo];
    updatedContactUsInfo[index].storePhoneNumbers.push("");
    setContactUsInfo(updatedContactUsInfo);
    IsSavedInputs();
  };
  /**
   * Removes a specific phone number field from a particular store.
   *
   * @param {number} storeIndex - The index of the store from which the phone number should be removed.
   * @param {number} phoneIndex - The index of the phone number in the store's phone numbers array that should be removed.
   */
  const handleRemovePhoneNumber = (storeIndex, phoneIndex) => {
    const updatedContactUsInfo = [...contactUsInfo];
    updatedContactUsInfo[storeIndex].storePhoneNumbers.splice(phoneIndex, 1);
    setContactUsInfo(updatedContactUsInfo);
    IsSavedInputs();
  };
  /**
   * Deletes a specific store entry from the contactUsInfo array.
   *
   * @param {number} index - The index of the store to be deleted.
   */
  const handleDeleteStore = (index) => {
    // Create a copy of the contactUsInfo array and remove the item at the specified index
    const updatedContactUsInfo = [...contactUsInfo];
    updatedContactUsInfo.splice(index, 1);
    setContactUsInfo(updatedContactUsInfo);
    IsSavedInputs();
  };
  /**
   * Adds a new empty store entry to the contactUsInfo array.
   */
  const handleStoreButtonClick = () => {
    setContactUsInfo((prevContactUsInfo) => [
      ...prevContactUsInfo,
      {
        primaryToggle: false,
        customStoreToggle: false,
        customDescription: null,
        storeAddress: "",
        storeName: "",
        storePhoneNumbers: [""], // Changed storePhoneNo to an array
        storeEmail: "",
        storeLocation: "",
      },
    ]);
    IsSavedInputs();
  };

  // Handler to submit contact us page details
  const handleSubmit = async () => {
    // Check if there are errors

    // Construct the payload
    const payload = {
      contact_us_page_description: contactUs.description,
      contact_us_store: contactUsInfo.map((store) => ({
        is_primary: store.primaryToggle ? 1 : 0,
        store_address: store.storeAddress,
        store_phone_number: store.storePhoneNumbers.join(','), // Adjust based on your requirements
        store_name: store.storeName,
        store_location: store.storeLocation,
        custom_description: store.customDescription,
        custom_store: store.customStoreToggle ? 1 : 0,
        store_email: store.storeEmail,
      })),
   };

    try {
      // Use axios to send the request
      const response = await axios.patch(
        `${REACT_APP_BASE_URL}/contact-us-page`,
        payload
      );

      if (response.status === 200) {
        if (dataSubmitted) {
          Swal.fire({
            title: "Success!",
            text: "Datas Updated successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: "Datas saved successfully.",
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
      fetchData();
    } catch (error) {
      // Handle error
      console.error("Error updating:", error);
    }
  };
  function formatPhoneNumbers(phoneNumberString) {
    // Check if phoneNumberString contains commas indicating multiple numbers
    if (phoneNumberString && phoneNumberString.includes(',')) {
      return phoneNumberString.split(',');
    } else {
      // If not, return phoneNumberString as a single-element array
      // This also handles the case where phoneNumberString might be empty or undefined
      return phoneNumberString ? [phoneNumberString] : [];
    }
  }
  // Function to fetch data about the contact us page
  const fetchData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/contact-us-page`);
      if (response.data) {
        setDataSubmitted(true);
      }
      if (response.status === 200) {
        setContactUs({
          contact_us_page_id: response.data[0].contact_us_page_id,
          description: response.data[0].contact_us_page_description,
        });
        prevDescriptionRef.current =
          response.data[0].contact_us_page_description;
        setContactUsInfo(
          response.data[0].contact_us_store.map((store) => ({
            contact_us_store_id: store.contact_us_store_id,
            primaryToggle: store.is_primary === 1,
            customStoreToggle: store.custom_store === 1,
            customDescription: store.custom_description,
            storeAddress: store.store_address,
            storePhoneNumbers: formatPhoneNumbers(store.store_phone_number), // Adjust if there are multiple phone numbers
            storeEmail: store.store_email,
            storeName: store.store_name,
            storeLocation: store.store_location,
          }))
        );
    }

// Helper function to format phone numbers

      const contactStoresData = response.data[0].contact_us_store.map(
        (store) => ({
          customDescription: store.custom_description,
        })
      );
      // Capture all the 'customDescription' data from contactStoresData
      const allCustomDescriptions = contactStoresData
        .map((store) => store.customDescription)
        .join(" ");
      prevDescriptionRef1.current = allCustomDescriptions;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Contact Us Page Not found "
      ) {
        setDataSubmitted(false);
      }
    }
  };

  // Effect hook to fetch contact us page data on component mount
  useEffect(() => {
    fetchData();
  }, []);
  // Function to validate form inputs
  const validateForm = () => {
    // Validate contactUs
    let formErrors = {};
    if (
      !contactUs.description ||
      !contactUs.description?.trim() ||
      contactUs.description === defaultDescription
    ) {
      formErrors.contactUsDescription = "Contact Us Description is required!";
    }

    contactUsInfo.forEach((info, index) => {
      if (info.customStoreToggle) {
        if (
          !info.customDescription?.trim() ||
          info.customDescription === defaultDescription
        ) {
          formErrors[`customDescription${index}`] =
            "Custom Description is required!";
        }

        return;
      }

      if (!info.storeAddress?.trim()) {
        formErrors[`storeAddress${index}`] = "Store Address is required!";
      }

      if (!info.storeName?.trim()) {
        formErrors[`storeName${index}`] = "Store Name is required!";
      }

      if (!info.storeLocation?.trim()) {
        formErrors[`storeLocation${index}`] = "Store Location is required!";
      }

      // Email Validation
      if (!info.storeEmail?.trim()) {
        formErrors[`storeEmail${index}`] = "Email is required!";
      } else if (!/\S+@\S+\.\S+/.test(info.storeEmail)) {
        formErrors[`storeEmail${index}`] = "Valid Email is required!";
      }

  
      // Phone Numbers Validation (assuming you want at least one valid phone number for each store)
      info.storePhoneNumbers.forEach((phoneNumber, phoneIndex) => {
        if (!phoneNumber?.trim()) {
          formErrors[`store${index}PhoneNumbers${phoneIndex}`] = "Phone number is required!";
        } else if (!isValidNumber(phoneNumber, 'IN')) { // 'IN' is the country code for India
          formErrors[`store${index}PhoneNumbers${phoneIndex}`] = "Valid phone number is required!";
        }
      });
    });

    setErrors(formErrors);
  };
  // Effect hook to validate form whenever contactUs or contactUsInfo changes
  useEffect(() => {
    validateForm();
  }, [contactUs, contactUsInfo]);
  
  return (
    <div className="px-2">
        <Accordion
          expanded={activeAccordion === "ContactUsPageAccordian"}
          onChange={handleAccordionChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="store-panel-content"
            id="store-panel-header"
          >
            <Typography
              className="font-medium text-xl"
              style={{ fontFamily: "poppins" }}
            >
              Contact Us Page
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <div className="w-full space-y-6 mb-6">
          <label className="font-medium font-poppins text-gray-600 block">
                Description
              </label>

              <SunEditor
                setDefaultStyle="font-family: poppins; font-size: 15px;"
                setOptions={{
                  height: 400,
                  buttonList: [
                    [
                      "font",
                      "fontSize",
                      "subscript",
                      "superscript",
                      "bold",
                      "italic",
                      "underline",
                    ], // Added 'font'
                    ["fontColor", "hiliteColor", "outdent", "indent"],
                    ["codeView", "undo", "redo", "align", "list", "table"], // Added 'codeView'
                    ["fullScreen", "preview"],
                  ],
                  // imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                  // To define custom fonts:
                  font: [
                    "Arial",
                    "Calibri",
                    "Comic Sans",
                    "Courier",
                    "Poppins",
                    "Times New Roman",
                  ],
                  // You can add other options according to your needs
                }}
                setContents={contactUs.description}
                onChange={(e) => {
                  // Compare the current content with the previous content
                  if (prevDescriptionRef.current !== e) {
                    IsSavedInputs();
                  }
                  prevDescriptionRef.current = e; // Update the ref with the new content

                  setContactUs((prevState) => ({
                    ...prevState,
                    description: e,
                  }));
                }}
              />

              <div className="mt-3">
                {errors.contactUsDescription && (
                  <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                    {errors.contactUsDescription}
                  </span>
                )}
              </div>
            </div>
            {contactUsInfo.map((store, index) => (
              <Accordion key={index} className="w-full  my-3">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`store-panel-content-${index}`}
                  id={`store-panel-header-${index}`}
                >
                  <Typography className="font-medium text-xl">
                    Store {index + 1}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="flex gap-4 ">
                  <div className="w-full space-y-6">
                    <div className="flex gap-3 items-center ">
                      <span className="font-poppins font-medium text-gray-600 ">
                        Primary
                      </span>
                      <ReactSwitch
                        checked={store.primaryToggle}
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        onChange={() => handlePrimaryToggleChange(index)}
                      />
                    </div>
                    <div className="flex items-center space-x-4 mb-4">
                      <label className="font-medium font-poppins text-gray-600">
                        Custom Store
                      </label>
                      <ReactSwitch
                        checked={store.customStoreToggle}
                        onChange={() => {
                          IsSavedInputs();
                          let updatedStores = [...contactUsInfo];
                          updatedStores[index].customStoreToggle =
                            !store.customStoreToggle;
                          setContactUsInfo(updatedStores); // this line was changed
                        }}
                      />
                    </div>

                    {store.customStoreToggle ? (
                      <>
                        <label className="font-medium font-poppins text-gray-600 block">
                          Custom Store Description
                        </label>
                        <SunEditor
                          setDefaultStyle="font-family: poppins; font-size: 15px;"
                          setOptions={{
                            height: 400,
                            buttonList: [
                              [
                                "font",
                                "fontSize",
                                "subscript",
                                "superscript",
                                "bold",
                                "italic",
                                "underline",
                              ],
                              ["fontColor", "hiliteColor", "outdent", "indent"],
                              [
                                "codeView",
                                "undo",
                                "redo",
                                "align",
                                "list",
                                "table",
                              ],
                              ["fullScreen", "preview"],
                            ],
                            // imageGalleryUrl:`${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                            font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Poppins",
                              "Times New Roman",
                            ],
                          }}
                          setContents={store.customDescription}
                          onChange={(content) => {
                            if (store.customDescription !== content) {
                              IsSavedInputs();
                              let updatedStores = [...contactUsInfo];
                              updatedStores[index].customDescription = content;
                              setContactUsInfo(updatedStores);
                            }
                          }}
                        />
                        <div className="mt-1">
                          {errors[`customDescription${index}`] && (
                            <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                              {errors[`customDescription${index}`]}
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="w-2/3 space-y-6">
                        <>
                          <label className="font-medium font-poppins text-gray-600 block">
                            Store Name
                          </label>
                          <input
                            type="text"
                            placeholder="Store Name"
                            value={store.storeName}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) =>
                              handleStoreFieldChange(
                                index,
                                "storeName",
                                e.target.value
                              )
                            }
                          />
                          <div className="mt-1">
                            {errors[`storeName${index}`] && (
                              <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                                {errors[`storeName${index}`]}
                              </span>
                            )}
                          </div>
                          <label className="font-medium font-poppins text-gray-600 block">
                            Store Address
                          </label>
                          <input
                            type="text"
                            placeholder="Store Address"
                            value={store.storeAddress}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) =>
                              handleStoreFieldChange(
                                index,
                                "storeAddress",
                                e.target.value
                              )
                            }
                          />
                          <div className="mt-1">
                            {errors[`storeAddress${index}`] && (
                              <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                                {errors[`storeAddress${index}`]}
                              </span>
                            )}
                          </div>
                          <label className="font-medium font-poppins text-gray-600 block">
                            Store Email
                          </label>
                          <input
                            type="text"
                            placeholder="Store Email"
                            value={store.storeEmail}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) =>
                              handleStoreFieldChange(
                                index,
                                "storeEmail",
                                e.target.value
                              )
                            }
                          />
                          <div className="mt-1">
                            {errors[`storeEmail${index}`] && (
                              <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                                {errors[`storeEmail${index}`]}
                              </span>
                            )}
                          </div>
                          <label className="font-medium font-poppins text-gray-600 block">
                            Store Location
                          </label>
                          <input
                            type="text"
                            placeholder="Store Location URL"
                            value={store.storeLocation}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) =>
                              handleStoreFieldChange(
                                index,
                                "storeLocation",
                                e.target.value
                              )
                            }
                          />
                          <div className="mt-1">
                            {errors[`storeLocation${index}`] && (
                              <span className=" text-red-700 mt-6 bg-red-50  font-poppins px-2 py-1 rounded shadow-md">
                                {errors[`storeLocation${index}`]}
                              </span>
                            )}
                          </div>
                          <div key={index}>
                            <label className="font-medium font-poppins py-4 text-gray-600 block">
                              Store Phone Numbers
                            </label>
                            {store.storePhoneNumbers?.map(
                              (phoneNumber, phoneIndex) => (
                                <div
                                  key={phoneIndex}
                                  className="flex flex-col mt-1 mb-2"
                                >
                                  <div className="flex items-center justify-between">
                                    <input
                                      type="text"
                                      placeholder="Store Phone Number"
                                      className="focus:ring-blue-500 focus:border-blue-500 flex-grow sm:text-sm border-gray-300 rounded-md"
                                      value={phoneNumber}
                                      onChange={(e) =>
                                        handleStorePhoneNumberChange(
                                          index,
                                          phoneIndex,
                                          e.target.value
                                        )
                                      }
                                    />

                                    {/* Display the remove button only if there's more than one phone number */}
                                    {store.storePhoneNumbers.length > 1 && (
                                      <button
                                        onClick={() =>
                                          handleRemovePhoneNumber(
                                            index,
                                            phoneIndex
                                          )
                                        }
                                        className="ml-2"
                                      >
                                        <MdRemoveCircleOutline
                                          size={20}
                                          color="red"
                                        />{" "}
                                        {/* Red Remove Icon */}
                                      </button>
                                    )}
                                  </div>

                                  <div className="flex-grow my-3">
                                    {errors[
                                      `store${index}PhoneNumbers${phoneIndex}`
                                    ] && (
                                      <span className="text-red-700 bg-red-50 font-poppins px-2 py-1 rounded shadow-md">
                                        {
                                          errors[
                                            `store${index}PhoneNumbers${phoneIndex}`
                                          ]
                                        }
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )
                            )}

                            <div className="flex items-center justify-start mt-1">
                              <button
                                onClick={() => handleAddPhoneNumber(index)}
                              >
                                <AddCircleIcon
                                  size={20}
                                  style={{ color: "green" }}
                                />{" "}
                                {/* Green Add Icon */}
                              </button>
                            </div>
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
                <div className="flex justify-end items-end p-3">
                  <button
                    onClick={() => handleDeleteStore(index)}
                    className="self-end flex items-center space-x-2 text-red-600 font-poppins p-3 border-red-500 border-2 rounded hover:border-red-300 hover:bg-red-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
                  >
                    <span>Delete</span>
                    <MdDelete size={20} />
                  </button>
                </div>
              </Accordion>
            ))}
            <button
              className="mb-2 mt-3 w-full sm:w-[50%] md:w-[30%] lg:w-[20%] xl:w-[15%] px-2 sm:px-4 py-1.5 sm:py-2 text-sm sm:text-base bg-fuchsia-600 text-white rounded hover:bg-fuchsia-700"
              onClick={handleStoreButtonClick}
            >
              Add Store
            </button>

            <button
              onClick={handleSubmit}
              className={`bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
                Object.keys(errors).length > 0
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={Object.keys(errors).length > 0}
            >
              {Object.keys(errors).length > 0
                ? "Fill the required fields"
                : dataSubmitted
                ? "Update"
                : "Save"}
            </button>
          </AccordionDetails>
        </Accordion>
      </div>
  );
};

export default ContactUs;
