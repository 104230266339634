import React from 'react'
import { RenderCommonUpload } from '../renderCommonUpload'

export const CollageBlockTen = ({IsSavedInputs,blockImages,setBlockImages,preview=false}) => {
  return (
    <div className={`w-full p-4 bg-gray-200 rounded-lg flex flex-col gap-4 `}>
        <div className="w-full flex justify-evenly gap-4">
        <div className="w-2/4 border-2 border-blue-500 md:h-[105px] lg:h-[136px]">
              <RenderCommonUpload buttonIndex={0} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"11.2:5.3"}/>
          </div>
          <div className="w-1/4 border-2 border-blue-500 md:h-[105px] lg:h-[136px]">
              <RenderCommonUpload buttonIndex={1} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"5.5:5.3"}/>
          </div>
          <div className="w-1/4 border-2 border-blue-500 md:h-[105px] lg:h-[136px]">
              <RenderCommonUpload buttonIndex={2} IsSavedInputs={IsSavedInputs} blockImages={blockImages} 
              setBlockImages={setBlockImages} preview={preview} dimension={"5.5:5.3"}/>
          </div>
        </div>
        
    </div>
  )
}
