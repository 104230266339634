import React, { useState, useEffect } from "react";
import { MdAdd, MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import axios from "axios";
import ReactSwitch from "react-switch";
// Import BASE_URL from config.js
import { BASE_URL } from "../../config";
const REACT_APP_BASE_URL = BASE_URL;

const Widgets = ({
  activeWidget,
  loading,
  setActiveWidget,
  clickedWidgetId,
  setClickedWidgetId,
  bannerDataSubmit,
  fetchWidgetOrder,
  widgets,
  setWidgets,
  widgetCounts,
  setWidgetCounts,
  setWidgetName,
  toggleBanner,
  setToggleBanner,
  setHasInteracted,
  refresh,
  setRefresh,
  setUnsavedChanges,
  unsavedChanges,
  hasInteracted,
  runTour,
  divRef,
  isModalVisible,
  setIsModalVisible,
  tourWidgetType,
  setWidgetAvailableInTheme,
  setImageCountForSelectedTemplate,
}) => {
  const [savedWidgetDetails, setSavedWidgetDetails] = useState(widgets);
  const [widgetOrders, setWidgetOrders] = useState([]);
  const onDragStart = (e, index) => {
    if (savedWidgetDetails[index].widget_type !== "banner") {
      e.dataTransfer.setData("itemIndex", index);
    }
  };
  const onDrop = (e, index) => {
    if (index !== 0) {
      const draggedIndex = e.dataTransfer.getData("itemIndex");
      const newList = Array.from(savedWidgetDetails);
      const [removed] = newList.splice(draggedIndex, 1);
      newList.splice(index, 0, removed);

      setWidgets(newList);

      // Prepare the payload for the PATCH request
      const updatedWidgets = newList.map((widget, idx) => ({
        widget_order_id: widget.widgetId ?? widget.widget_order_id,
        widget_name: widget.widget_name,
        order_by: idx + 1,
      }));

      // Make the PATCH request
      axios
        .patch(`${REACT_APP_BASE_URL}/widget-order`, updatedWidgets)
        .then(() => {
          fetchWidgetOrder();
          // Show SweetAlert2 notification after the successful API call
          Swal.fire({
            title: "Success!",
            text: "Widget order has been updated.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.error(err);
          // Optionally, show an error alert
          Swal.fire({
            title: "Error!",
            text: "Failed to update widget order.",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  useEffect(() => {
    setSavedWidgetDetails(widgets);
  }, [widgets]);

  const DraggableWidget = ({
    title,
    deleteFunc,
    onDragOver,
    onDrop,
    onClick,
    onDragStart,
    draggable,
    widgetType,
    id,
    clickedWidgetId,
    isToggleVisible,
    toggleFuncs,
    widgetId,
    toggleBanner,
  }) => {
    const isDraggable = widgetType !== "Banner" && draggable;
    const isActive = widgetId === clickedWidgetId;
    const isBanner = typeof title === "string" && widgetType === "Banner";
    const cssClass = isActive
      ? "flex justify-between items-center cursor-move border-2 border-blue-600 p-4 m-2 rounded-lg shadow-md transition duration-500 ease-in-out"
      : isBanner
      ? "flex justify-between cursor-move items-center border-2 border-gray-300 p-4 m-2 rounded-lg shadow-md transition duration-500 ease-in-out"
      : "flex justify-between cursor-move items-center border-2 border-gray-300 p-4 m-2 rounded-lg shadow-md transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110";
    const handleToggle = async (checked) => {
      // setToggleBanner(checked);
      try {
        await axios.patch(`${REACT_APP_BASE_URL}/custom-banner/${widgetId}`, {
          is_enabled: checked ? 1 : 0,
        });

        // call your original toggle function after successful API call
        toggleFuncs(checked);
      } catch (error) {
        console.error("Error updating banner:", error);
      }
    };
    return (
      <div
        draggable={isDraggable} // Update here
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragStart={onDragStart}
        id={id}
        onClick={onClick}
        className={`${cssClass} ${
          isBanner && toggleBanner ? "" : isBanner ? "opacity-50" : ""
        } ${isBanner && "third-step"}`}
      >
        <h2 className="font-bold text-gray-700">{title}</h2>
        <div className="flex items-center">
          {isToggleVisible ? (
            <label>
              <ReactSwitch
                checked={toggleBanner}
                onChange={handleToggle}
                offColor="#767777"
                onColor="#81b0ff"
                offHandleColor="#ffffff"
                onHandleColor="#ffffff"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={18}
                width={30}
                className="react-switch fourth-step"
              />
            </label>
          ) : (
            <>
              <MdDelete
                onClick={(e) => {
                  e.stopPropagation(); // Prevent parent onClick from being triggered
                  deleteFunc(); // Call delete function
                }}
                className="text-red-500 hover:text-red-700 cursor-pointer"
                size={24}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const AddWidgetButton = ({ showFunc }) => {
    return (
      <div className="flex justify-center items-center m-2 twenty-seventh-step">
        <MdAdd
          onClick={showFunc}
          className="text-green-500 hover:text-green-700 cursor-pointer transform transition duration-500 hover:scale-125"
          size={24}
        />
      </div>
    );
  };

  const WidgetModal = ({ isVisible, addFunc, hideFunc }) => {
    const widgetTypes = [
      "Collage",
      "Info Slider",
      "Product Slider",
      "Category Slider",
      "Subscription",
      "Cms Custom",
    ];

    const [selectedWidgetType, setSelectedWidgetType] = useState(
      widgetTypes[0]
    );

    const handleClick = () => {
      addFunc(selectedWidgetType);
      setSelectedWidgetType(widgetTypes[0]);
      hideFunc();
    };

    return (
      isVisible && (
        <div className="fixed z-10 inset-0 overflow-y-auto fourteenth-step">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Add a widget
                    </h3>
                    <div className="mt-2 twenty-eight-step">
                      <select
                        value={selectedWidgetType}
                        onChange={(e) => setSelectedWidgetType(e.target.value)}
                        className="w-full px-3 py-2 text-base text-gray-700 bg-white rounded-md focus:outline-none"
                      >
                        {widgetTypes.map((type, index) => (
                          <option value={type} key={index}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleClick}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={hideFunc}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  // Event handlers...
  const handleWidgetClick = (widget) => {
    localStorage.setItem("widgetType", widget.widgetType);
    localStorage.setItem("widget_name", widget.widget_name);
    localStorage.setItem("widgetId", widget.widgetId);
    setImageCountForSelectedTemplate(0);
    if (unsavedChanges && hasInteracted) {
      Swal.fire({
        title: "Unsaved changes",
        text: "You have unsaved changes. Are you sure you want to leave?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, leave",
        cancelButtonText: "No, stay",
      }).then((result) => {
        if (result.isConfirmed) {
          setUnsavedChanges(false);
          setHasInteracted(false);
          setRefresh(null);
          setActiveWidget(widget.widgetType); // Set the new widget only if user confirmed
          setWidgetName(widget.widget_name);
          setClickedWidgetId(widget.widgetId);
          setUnsavedChanges(true);
        }
      });
    } else {
      setActiveWidget(widget.widgetType); // Set the new widget if there are no unsaved changes
      setWidgetName(widget.widget_name);
      setClickedWidgetId(widget.widgetId);
      setRefresh(!refresh);
    }
  };

  const fetchWidgetOrderAdd = (widgetName) => {
    setWidgets([...savedWidgetDetails, widgetName]);

    // widgets.push({widgetType: widgetName.widgetType})
    // fetchWidgetOrder();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const deleteFunc = async (index, widgetId) => {
    // const widgetIdG = widgets[index].widgetId;
    // const preiviousWidget = widgets[index - 1];
    // console.log("preiviousWidget", preiviousWidget);

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete it!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axios
        .delete(`${REACT_APP_BASE_URL}/widget-order/` + widgetId)
        .then((response) => {
          const data = response.data;
          // if (data.status === "success") {
          //   const getWidgetResponse = data.data.widget_order;
          //   const lastIndex = getWidgetResponse.length - 1;
          //   const lastWidget = getWidgetResponse[lastIndex];
          //   const lastWidgetDet = {
          //     widget_name: lastWidget.widget_name,
          //     widgetType: lastWidget.widget_type,
          //     widgetId: lastWidget.widget_order_id,
          //   };
          //   handleWidgetClick(lastWidgetDet);
          //   const transformedWidgetResponse = getWidgetResponse.map(
          //     (widget) => ({
          //       widgetId: widget.widget_order_id,
          //       widget_name: widget.widget_name,
          //       order_by: widget.order_by,
          //       widgetType: widget.widget_type,
          //     })
          //   );
          //   setSavedWidgetDetails(transformedWidgetResponse);
          //   // fetchWidgetOrder()
          //   // setActiveWidget(widget.widgetType)
          //   // setWidgetName(widget.widget_name)
          //   // setClickedWidgetId(widget.widgetId)
          //   // if (getWidgetID.widget_order_id === widgetId) {
          //   //     const preiviousWidget = getWidgetResponse[index - 1]
          //   //     handleWidgetClick(preiviousWidget);
          //   //   }
          //   Swal.fire("Deleted!", "Your widget has been deleted.", "success");
          // } else {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Oops...",
          //     text: "Something went wrong!",
          //   });
          // }
          if (data.status === "success") {
            fetchWidgetOrder();
            // const preiviousWidget = widgets[index - 1];
            const preiviousWidget = widgets[0];
            handleWidgetClick(preiviousWidget);
            Swal.fire("Deleted!", "Your widget has been deleted.", "success");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };
  const createWidgetOrder = (title, order, type) => {
    // count how many widgets with this title exist
    const widgetCount = savedWidgetDetails?.reduce(
      (count, widget) =>
        widget?.widget_name?.startsWith(title) ? count + 1 : count,
      0
    );
    // append the count to the title
    const newTitle = `${title} ${widgetCount + 1}`;

    // New Widget Api Call
    const payload = {
      widget_name: newTitle,
      widget_type: type,
    };
    axios
      .post(`${REACT_APP_BASE_URL}/widget-order`, payload)
      .then((response) => {
        if (response.status === 201) {
          const order =
            response.data?.data?.widgetsOrder[
              response.data?.data?.widgetsOrder?.length - 1
            ].order_by;
          fetchWidgetOrder();
          fetchWidgetOrderAdd({
            widget_name: newTitle,
            order_by: order,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addFunc = (type) => {
    const count = widgetCounts[type] || 0;
    const title = count > 1 ? `${type} ${count + 1}` : type;

    setWidgetCounts({ ...widgetCounts, [type]: count + 1 });

    const maxOrder = widgetOrders.length > 0 ? Math.max(...widgetOrders) : 0;
    const order = maxOrder + 1;

    setWidgetOrders([...widgetOrders, order]);

    createWidgetOrder(title, order, type); // Here, pass the newly formed 'title', order and type
  };
  const ShimmerPlaceholder = () => (
    <div className="flex justify-between items-center border-2 border-gray-300 p-4 m-2 rounded shadow-md transition duration-500 ease-in-out w-full">
      <div className="shimmer h-4 w-full rounded"></div>
    </div>
  );

  const getFirstWidgetIndexByType = (type) => {
    return savedWidgetDetails.findIndex((widget) => widget.widgetType === type);
  };
  const isWidgetAvailable = (type) => {
    return savedWidgetDetails.some((widget) => widget.widgetType === type);
  };

  useEffect(() => {
    if (!runTour || savedWidgetDetails.length == 0) return;
    console.log("tourWidgetType", tourWidgetType);
    console.log(
      "isWidgetAvailable(tourWidgetType)",
      setWidgetAvailableInTheme(
        savedWidgetDetails
          .map((widget) => widget.widgetType)
          .filter((value, index, self) => self.indexOf(value) === index)
      )
    );
    if (!isWidgetAvailable(tourWidgetType)) return;
    handleWidgetClick(
      savedWidgetDetails[getFirstWidgetIndexByType(tourWidgetType)]
    );
  }, [tourWidgetType, runTour]);

  return (
    <div className="w-full px-3 overflow-y-auto h-full" ref={divRef}>
      <h1 className="text-lg font-bold font-poppins text-gray-700 flex justify-center">
        Widget Order
      </h1>
      {loading ? (
        <>
          {/* <Loader /> */}
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
        </>
      ) : (
        savedWidgetDetails.map((widget, index) => (
          <DraggableWidget
            className="cursor-pointer"
            title={widget.widget_name}
            onClick={() => handleWidgetClick(widget)}
            deleteFunc={() => deleteFunc(index, widget.widgetId)}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, index)}
            onDragStart={(e) =>
              widget.widget_type !== "banner" && onDragStart(e, index)
            }
            id={`widget-${index}`}
            isToggleVisible={index === 0}
            toggleFuncs={() => setToggleBanner(!toggleBanner)} // Corrected prop name and function
            bannerDataSubmit={bannerDataSubmit}
            widgetType={widget.widgetType}
            widgetId={widget.widgetId}
            clickedWidgetId={clickedWidgetId}
            toggleBanner={toggleBanner}
            activeWidget={activeWidget}
            draggable={widget.widgetType !== "Banner"}
            key={index}
            setImageCountForSelectedTemplate={setImageCountForSelectedTemplate}
          />
        ))
      )}
      <AddWidgetButton showFunc={() => setIsModalVisible(true)} />
      <WidgetModal
        isVisible={isModalVisible}
        addFunc={addFunc}
        hideFunc={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default Widgets;
